import cln from 'classnames';
import { MixerTransactionAdditionalInfo } from 'decryption_protocol/dist/lib/store/operations/types';

import BonusCardLayoutComponentKit from 'components/ui/KitComponents/BonusCardLayoutComponentKit';

import { APP_TYPE, IS_FSWIN } from 'constants/ui';

import styles from './MonthlyCard.module.css';

type Props = {
  info: MixerTransactionAdditionalInfo;

  id: number;
};

const MonthlyCard = ({ info, id }: Props) => {
  const { resultValue, endTimestamp } = info;

  return (
    <BonusCardLayoutComponentKit
      title="newBonuses.cards.monthly"
      description="newBonuses.cards.monthlyDescription"
      id={id}
      value={`${resultValue}`}
      endTimestamp={endTimestamp}
    >
      <img
        src={`/icons-elon/bonuses/cards/${APP_TYPE}/monthly.png`}
        className={cln(styles.wrapperMonthlyCard, {
          [styles.imgHeight]: IS_FSWIN,
        })}
      />
    </BonusCardLayoutComponentKit>
  );
};

export default MonthlyCard;
