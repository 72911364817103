import { useNavigate } from 'react-router';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import cln from 'classnames';

import { ReactComponent as CloseSvg } from 'assets/base/close.svg';
import { ReactComponent as ArrowSvg } from 'assets/base/arrow.svg';
import CountDownKit from 'components/ui/KitComponents/CountDownKit';
import { Text } from 'decryption_component-kit';
import ProviderCasinoGame from './components/ProviderCasinoGame';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { ERouteNames } from 'Routes/types';
import { EGettingInfoStatus } from 'types/hooks';
import { ModalTypes } from 'stores/modal/types';
import { Props } from './types';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { CASINO_LIMIT_GAMES } from 'constants/transport';

import styles from './ProviderTournament.module.css';

import animation from 'styles/animation.module.css';

const ProviderTournament = (props: Props) => {
  const {
    id,
    image,
    handleArrowLeft,
    handleArrowRight,
    endDate,
    isFinished = false,
    gameIds,
    providerName,
    rules,
    isArrow,
    sliderInfo,
    prize,
    classes,
    // TODO: fix it
    isVerticalView,
  } = props;

  const slicedGames = isVerticalView
    ? gameIds
    : gameIds.slice(0, CASINO_LIMIT_GAMES);

  const [isShowInfoPopUp, setInfoPopUp] = useState<boolean>(false);

  const navigate = useNavigate();

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  // const swipeHandlers = useSwipeable({
  //   onSwipedLeft: () => handleArrowLeft?.(),
  //   onSwipedRight: () => handleArrowRight?.(),
  //   delta: 180,
  // });

  const jumpToProviderPage = () => {
    closeModal(ModalTypes.Unknown);

    navigate(ERouteNames.ProviderBattles.replace(':id', `${id}`));
  };

  const toggleInfoPopUp = () => {
    const newState = !isShowInfoPopUp;

    setInfoPopUp(newState);
  };

  return (
    <div className={cln(styles.wrapperTournamentProvider, classes)}>
      <div className={styles.relative}>
        {isArrow && handleArrowLeft && (
          <div
            className={cln(styles.arrowLeft, animation.animationFadeOut)}
            style={{ animationDelay: '1s' }}
            onClick={handleArrowLeft}
          >
            <ArrowSvg className={styles.rotateLeft} />
          </div>
        )}

        <img src={image} className={styles.image} />

        {isArrow && handleArrowRight && (
          <div
            className={cln(styles.arrowRight, animation.animationFadeOut)}
            style={{ animationDelay: '1s' }}
            onClick={handleArrowRight}
          >
            <ArrowSvg className={styles.rotateRight} />
          </div>
        )}

        {sliderInfo}
      </div>

      <div className={styles.wrapperTitle}>
        <div className={styles.prizPool}>
          <Text
            idT="tournaments.prize"
            specValue={`${prize.toLocaleString('de-DE')}`}
          />
        </div>

        {isFinished ? (
          <div className={styles.finished}>
            <Text idT="tournaments.notActive" />
          </div>
        ) : (
          <CountDownKit
            endTimestamp={endDate}
            wrapperClasses={styles.marginL}
          />
        )}
      </div>

      <div
        className={cln(styles.gridItems, {
          [styles.verticalGrid]: isVerticalView,
        })}
      >
        {slicedGames.map((gameId) => (
          <ProviderCasinoGame gameId={gameId} key={gameId} />
        ))}

        {!isVerticalView && (
          <div
            className={styles.openProviderGames}
            onClick={jumpToProviderPage}
          >
            <Text
              idT="tournaments.showMoreProviderGames"
              specValue={providerName}
            />
          </div>
        )}
      </div>

      {!isVerticalView && (
        <>
          <ComponentKitBaseButton
            text="tournaments.play"
            size="--button-full"
            buttonHeight="--button-height-l"
            color="primary"
            handleClick={jumpToProviderPage}
            centreText
            classes={styles.buttonPlay}
          />

          <div className={styles.dash} />
        </>
      )}

      <ComponentKitBaseButton
        text="tournaments.rules"
        size="--button-full"
        buttonHeight="--button-height-l"
        color="dark"
        centreText
        handleClick={toggleInfoPopUp}
      />

      {isShowInfoPopUp &&
        ReactDOM.createPortal(
          <div className={styles.blackContainer}>
            <div className={styles.rules}>
              <div className={styles.title}>
                <Text idT="tournaments.rules" />

                <CloseSvg onClick={toggleInfoPopUp} />
              </div>

              <div className={styles.content}>
                {rules.map((r) => (
                  <div className={styles.text} key={r}>
                    {r}
                  </div>
                ))}
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default ProviderTournament;
