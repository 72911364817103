import React, { useEffect } from 'react';
import { useAuth } from 'decryption_protocol_hooks';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { IS_DEVELOPMENT_MODE, TELEGRAM_CHAT_ID } from 'constants/ui';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getClientIdSelector } from 'stores/user/personal/selectors';

type Props = {
  children: React.ReactElement;
};

const HOCTelegram = ({ children }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );
  const userInfo = useAuth({ isConnectedApp });

  const clientId = useZustandUserStore(getClientIdSelector);

  const saveToken = async (token: string, clientId: number) => {
    try {
      const url = IS_DEVELOPMENT_MODE
        ? 'http://localhost:8001'
        : `${window.location.origin}/tgb`;

      const res = await fetch(`${url}/user/assign-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'telegram-id': TELEGRAM_CHAT_ID,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ clientId }),
      });

      await res.json();
    } catch (error) {
      alert(error);
      console.error('🚀 :', error);
    }
  };

  useEffect(() => {
    if (!userInfo || !TELEGRAM_CHAT_ID || !clientId) {
      return;
    }

    saveToken(userInfo.token, clientId);
  }, [userInfo, clientId]);

  return children;
};

export default HOCTelegram;
