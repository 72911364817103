import { useEffect, useRef, useState } from 'react';
import cln from 'classnames';
import { AdaptedGame } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import { ReactComponent as ArrowTopSvg } from 'assets/base/arrow-back.svg';
import CasinoGame from 'components/CasinoComponents/CasinoGame';
import ComponentKitBaseButton from '../KitComponents/Buttons/BaseButton';

import {
  INFINITY_SCROLL_BUTTON_TOP_OFFSET,
  INFINITY_SCROLL_OFFSET,
  IS_VERTICAL,
} from 'constants/ui';

import styles from './CasinoGridGames.module.css';
import animation from 'styles/animation.module.css';

type Props = {
  games: AdaptedGame[];
  isLastPage: boolean;
  handleClickShowMore: () => void;
  classes?: string;
};

const CasinoGridGames = (props: Props) => {
  const { games, isLastPage, handleClickShowMore, classes } = props;

  const [buttonToTop, setButtonToTop] = useState(false);

  const wrapperGridGame = useRef<HTMLDivElement | null>(null);

  const jumpTo = () => {
    window.scrollTo?.({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const trackScrolling = () => {
      if (!wrapperGridGame.current) {
        return;
      }

      if (
        wrapperGridGame.current.getBoundingClientRect().top <=
        INFINITY_SCROLL_BUTTON_TOP_OFFSET
      ) {
        setButtonToTop(true);
      } else {
        setButtonToTop(false);
      }

      if (
        wrapperGridGame.current.getBoundingClientRect().bottom <
          INFINITY_SCROLL_OFFSET &&
        !isLastPage
      ) {
        handleClickShowMore();
      }
    };

    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, [isLastPage, handleClickShowMore]);

  return (
    <>
      <div
        className={cln(
          styles.wrapperGridCasinoGame,
          {
            [styles.verticalGrid]: IS_VERTICAL,
          },
          classes
        )}
        ref={wrapperGridGame}
      >
        {games.map((game) => (
          <CasinoGame key={game.id} {...game} />
        ))}
      </div>

      {!isLastPage && (
        <ComponentKitBaseButton
          text="showMoreBtn"
          handleClick={handleClickShowMore}
          size="--button-full"
          buttonHeight="--button-height-xl"
          color="dark"
          centreText
        />
      )}

      {buttonToTop && (
        <div
          //  TODO this button showed when page mount
          className={cln(styles.buttonTop, {
            [animation.animationMoveToUpBtn]: buttonToTop,
            [animation.animationMoveToDownBtn]: !buttonToTop,
          })}
          onClick={jumpTo}
        >
          <ArrowTopSvg className={styles.iconTop} />
        </div>
      )}
    </>
  );
};

export default CasinoGridGames;
