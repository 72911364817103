import React from 'react';
import { ENotificationType } from 'stores/notification/types';

import { ReactComponent as SuccessSvg } from 'assets/notifications/success.svg';
import { ReactComponent as WarningSvg } from 'assets/notifications/warning.svg';
import { ReactComponent as ErrorSvg } from 'assets/notifications/error.svg';

type Props = {
  type: ENotificationType;
};

export const NotificationTypeIcon = ({ type }: Props) => {
  if (type === ENotificationType.Success) {
    return <SuccessSvg />;
  }

  if (type === ENotificationType.Warning) {
    return <WarningSvg />;
  }

  if (type === ENotificationType.Error) {
    return <ErrorSvg />;
  }

  return <div>def</div>;
};
