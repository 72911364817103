import { useEffect } from 'react';

import Input from 'components/ui/Input/Base';

import { ReactComponent as SearchSvg } from 'assets/base/search.svg';
import styles from './CasinoSearchInput.module.css';

import { useZustandCasinoSearch } from 'stores/casino/search';
import { setCasinoSearchValueAction } from 'stores/casino/search/selectors';

type Props = {
  classes?: string;
};

const CasinoSearchInput = ({ classes }: Props) => {
  const setSearchValue = useZustandCasinoSearch(setCasinoSearchValueAction);

  useEffect(
    () => () => {
      setSearchValue('');
    },
    []
  );

  return (
    <Input
      size="full"
      placeholder="search"
      onChange={setSearchValue}
      isHidePlaceholder
      classes={classes}
    >
      <SearchSvg className={styles.searchIcon} />
    </Input>
  );
};

export default CasinoSearchInput;
