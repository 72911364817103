import { useMemo } from 'react';
import {
  Banners,
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';

import { useBalance, useAuth } from 'decryption_protocol_hooks';
import { useNavigate } from 'react-router';
import cln from 'classnames';

import HomeCategories from './components/HomeCategories';

import CasinoCategories from 'components/CasinoComponents/CasinoCategories';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import {
  getAdditionalCategoryInfoSelector,
  getHomeBannersSelector,
} from 'stores/configuration/selectors';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';

import { EBannerTypes } from './types';
import { AuthInfo } from 'decryption_protocol/dist/lib/store/user/types';
import { ERouteNames } from 'Routes/types';
import { LotteryTypes } from 'stores/lottery/types';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';
import { ModalTypes } from 'stores/modal/types';
import { BalanceInfo } from 'decryption_protocol/dist/lib/store/user/entities/balance/types';
import { Banner } from 'decryption_component-kit/dist/lib/components/Banners/types';

import styles from './Home.module.css';
import animation from 'styles/animation.module.css';
import './sliderHome.css';
import { APP_TYPE } from 'constants/ui';
import LastBigWinnersKit from 'components/ui/KitComponents/LastBigWinnersKit';

const CasinoHome = () => {
  const { getText } = useTranslate('', '');

  const navigate = useNavigate();

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const setModal = useZustandModalStore(setModalInfoAction);

  const activeLang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const banners = useZustandWebConfiguration(getHomeBannersSelector);

  const isAuthInfo = useAuth({ isConnectedApp }) as AuthInfo;

  const balanceInfo = useBalance({
    isConnectedApp,
    isBalanceInfo: true,
  }) as BalanceInfo | null;

  const additionalCategoryInfo = useZustandWebConfiguration(
    getAdditionalCategoryInfoSelector
  );

  const handleOpenTG = () => {
    const a = document.createElement('a');

    // a.href = `https://t.me/elonbetcasinobot?start=utm_source=Site-utm_media=${window.location.origin}-clickid=banner`;
    a.href =
      'https://click.eloncas.com/vvhq65qY?landing=274&sub_id1=product&sub_id2=smm_prooduct&sub_id3=telegram0708&sub_id4=tg_butt';
    a.target = '_blank';
    a.click();
  };

  const handleBannerClick = (id: keyof typeof EBannerTypes) => () => {
    switch (id) {
      case 'Asian':
        additionalCategoryInfo &&
          navigate(
            ERouteNames.CasinoProviders.replace(
              ':providerNames',
              additionalCategoryInfo.providerCategoryNames
            )
          );
        break;
      case 'Betting':
        navigate(ERouteNames.FrameBetting);
        break;
      case 'TelegramBot':
        return handleOpenTG();
      case 'Originals':
        navigate(
          ERouteNames.CasinoCategories.replaceAll(
            ':categoryId',
            `${ECasinoCategoryIds.Turbo}`
          ).replace(':subCategoryId', 'all')
        );

        break;
      case 'Lottery':
        navigate(ERouteNames.Lottery.replace(':type', LotteryTypes[0]));
        break;
      case 'Referral':
        navigate(ERouteNames.ReferralProgram);
        break;
      case 'Welcome':
        setModal(isAuthInfo ? ModalTypes.Bonuses : ModalTypes.SignIn);
        break;

      default:
        break;
    }
  };

  const adaptedBanners = useMemo(() => {
    const { successDepositCnt = 0 } = balanceInfo || {};

    const adaptedBanners: Banner[] = [];

    banners.forEach((b) => {
      if ('depositDependency' in b) {
        const { depositDependency } = b as Banner & {
          depositDependency: number;
        };

        if (depositDependency <= successDepositCnt) {
          return true;
        }
      }
      adaptedBanners.push(b);
    });

    return adaptedBanners;
  }, [getText, balanceInfo]);

  return (
    <>
      <div className={cln(styles.wrapperCasinoHome, animation.animationFadeIn)}>
        <CasinoCategories type="buttons" withoutSubCategory withoutSearch />

        <div className={cln(styles.wrapperSlider, styles.width)}>
          <Banners
            appType={APP_TYPE}
            isAuth={Boolean(isAuthInfo)}
            isMobile={isMobile}
            banners={adaptedBanners}
            handleClick={handleBannerClick}
            language={activeLang}
          />
        </div>

        <HomeCategories />
      </div>

      <LastBigWinnersKit classes={styles.marginB} />
    </>
  );
};

export default CasinoHome;
