import { useNavigate } from 'react-router';
import { useCasinoGames } from 'decryption_protocol_hooks';
import { CasinoGamesResponse } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import CasinoGame from 'components/CasinoComponents/CasinoGame';
import { Text } from 'decryption_component-kit';

import { ERouteNames } from 'Routes/types';

import { CASINO_LIMIT_GAMES } from 'constants/transport';
import { IS_VERTICAL } from 'constants/ui';
import { getDeviceTypeSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { EDeviceType } from 'stores/device/types';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './RowGames.module.css';

type Props = {
  subCategoryId?: number | null;
  categoryId: number;
};

const FACTOR_MOBILE: [number, number] = [3.3, 2.2];

const FACTOR_TABLET: [number, number] = [5.3, 4.2];

const FACTOR_DESKTOP: [number, number] = [8.8, 8];

const RowGames = ({ categoryId, subCategoryId = null }: Props) => {
  const device = useZustandDeviceStore(getDeviceTypeSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const gameInfo = useCasinoGames({
    categoryId,
    subCategoryId,
    limit: CASINO_LIMIT_GAMES,
    page: 0,
    search: '',
    providerId: null,
    isMobile: device !== EDeviceType.Desktop,
    isConnectedApp,
  }) as CasinoGamesResponse;

  const navigate = useNavigate();

  const jumpToCategory = () => {
    const pathname = ERouteNames.CasinoCategories.replaceAll(
      ':categoryId',
      `${categoryId}`
    ).replaceAll(':subCategoryId', `${subCategoryId || 'all'}`);

    navigate(pathname);
  };

  if (!gameInfo?.games) {
    return (
      <div className={styles.rowCasinoGamesWrapper}>
        <SkeletLoader />
      </div>
    );
  }

  let factors = FACTOR_MOBILE;

  if (device === EDeviceType.Tablet) {
    factors = FACTOR_TABLET;
  }

  if (device === EDeviceType.Desktop) {
    factors = FACTOR_DESKTOP;
  }

  return (
    <div
      className={styles.rowCasinoGamesWrapper}
      style={{
        gridAutoColumns: `${Math.floor(
          window.innerWidth / (IS_VERTICAL ? factors[0] : factors[1])
        )}px`,
      }}
    >
      {gameInfo?.games.map((g: any) => (
        <CasinoGame {...g} key={g.id} />
      ))}

      <div className={styles.showAll} onClick={jumpToCategory}>
        <Text idT="showMoreBtn" />
      </div>
    </div>
  );
};

export default RowGames;
