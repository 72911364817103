import cln from 'classnames';

import { ReactComponent as ArrowSvg } from 'assets/base/arrow.svg';

import styles from './ArrowButton.module.css';

type Props = {
  isOpened?: boolean;
  toggleState?: (e: any) => void;
  classes?: string;
};

const ArrowButton = ({ isOpened, toggleState, classes = '' }: Props) => (
  <ArrowSvg
    className={cln(styles.default, classes, {
      [styles.openedColor]: isOpened,
    })}
    onClick={toggleState}
  />
);

export default ArrowButton;
