import { useMemo, useState } from 'react';
import {
  Text,
  useZustandLanguagesKitStore,
  BaseButton,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';

import CurrencySelector from '../../CurrencySelector';

import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import ComponentKitBaseInput from 'components/ui/KitComponents/Inputs/ComponentKitBaseInput';
import RegistrationPromoCode from '../../PromoCode';
import DocsCheckBox from '../DocsCheckBox';

import { composeValidations, isEmail, isPassword } from 'utils/validation';

import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import {
  getActiveCurrencyIdSelector,
  setRegisteredLoginAction,
} from 'stores/forms/signUp/selectors';

import { useTransportControllers } from 'decryption_protocol_hooks';
import { getRegistrationData } from 'utils/transport/main';
import {
  ERequestIds,
  RegistrationSubType,
} from 'decryption_protocol/dist/lib/binary/types';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import { getDocsSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import animation from 'styles/animation.module.css';
import styles from '../../../SignUp.module.css';

const Email = ({ isSelected = false }: { isSelected: boolean }) => {
  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const { sendCommand } = useTransportControllers();

  const docs = useZustandWebConfiguration(getDocsSelector) || {};

  const { getText } = useTranslate('', '');

  const [password, setPassword] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const setRegisteredLogin = useZustandRegistrationStore(
    setRegisteredLoginAction
  );

  const activeCurrencyId = useZustandRegistrationStore(
    getActiveCurrencyIdSelector
  );

  const activeLang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const [terms, setTermsState] = useState(isSelected);

  const [age, setAgeState] = useState(isSelected);

  const isDisabledSubmitButton = useMemo(
    () =>
      Boolean(
        !activeCurrencyId ||
          !email.value ||
          email.errorText ||
          !password.value ||
          password.errorText ||
          !terms ||
          !age
      ),
    [activeCurrencyId, email, password, terms, age]
  );

  const [promoCode, setPromoCode] = useState('');

  const handleChange = (t: 'email' | 'password') => (value: string) => {
    if (t === 'password') {
      const errorText = composeValidations([
        isPassword({
          value,
          errorText: ['errors.passwordMin', 'errors.passwordMax'],
        }),
      ]);

      setPassword({
        value,
        errorText,
      });

      return;
    }

    const emailResponse = isEmail({
      value,
      errorText: 'errors.email',
    })();

    setEmail(emailResponse);
  };

  const handleSubmitForm = () => {
    if (!activeCurrencyId) {
      return;
    }

    const sendData = getRegistrationData({
      registrationType: promoCode
        ? RegistrationSubType.PromoEmail
        : RegistrationSubType.Email, // 06x - for EMail only
      countryId: 0,
      currencyId: activeCurrencyId,
      promoCode,

      countryName: '', // TODO back not validate this value
    });

    setRegisteredLogin(email.value);

    sendCommand(
      promoCode ? '06x_with_promo' : '06x',
      {
        ...sendData,
        email: email.value,
        password: password.value,
        letters: 0, // mock
        promoCode,
      },
      true
    );
  };

  const handleToggleState = (name: 'terms' | 'age') => (d: boolean) => {
    const toggleFn = name === 'terms' ? setTermsState : setAgeState;

    toggleFn(d);
  };

  const handleOpenDoc = () => {
    if (!activeLang) {
      return;
    }
    // TODO terms
    const link = docs[1]?.link.replace('[lang]', activeLang);

    if (!link) {
      return;
    }

    window.open(link);
  };

  return (
    <div className={animation.animationFadeIn}>
      <ComponentKitBaseInput
        placeholder="registrations.inputs.email"
        size="full"
        isHidePlaceholder
        onChange={handleChange('email')}
        errorText={email.errorText}
        classes={styles.marginB}
      />

      <ComponentKitBaseInput
        placeholder="registrations.inputs.password"
        size="full"
        isHidePlaceholder
        onChange={handleChange('password')}
        errorText={password.errorText}
        classes={styles.marginB}
      />

      {/* <CountrySelector /> */}

      <CurrencySelector />

      <RegistrationPromoCode setPromoCode={setPromoCode} />

      <DocsCheckBox
        terms={terms}
        age={age}
        handleOpenDoc={handleOpenDoc}
        handleToggleState={handleToggleState}
      />

      <HOCRequestLoader
        command="'\\06x'"
        requestId={ERequestIds.Registration}
        customLoader={
          <div
            style={{ height: ' var(--height-48)' }}
            className={styles.marginB}
          >
            <Text idT="loading" />
          </div>
        }
      >
        <BaseButton
          text={getText('registrations.submitBtn')}
          disabled={isDisabledSubmitButton}
          color="primary"
          handleClick={handleSubmitForm}
          size="--button-full"
          centreText
          buttonHeight="--button-height-full"
          classes={styles.marginB}
        />
      </HOCRequestLoader>
    </div>
  );
};

export default Email;
