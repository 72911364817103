import { ModalLayout, Text, useTranslate } from 'decryption_component-kit';
import { useTransportControllers } from 'decryption_protocol_hooks';

import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { useZustandModalStore } from 'stores/modal/store';
import {
  closeAndOpenModalAction,
  getModalAdditionalInfoSelector,
  setModalInfoAction,
} from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './ClaimConfirmation.module.css';

const ClaimConfirmation = () => {
  const { sendCommand } = useTransportControllers();

  const { getText } = useTranslate('', '');

  const {
    id: operationId,
    previousModalType,
    titleKey = 'bonusCabinet.cancelBonus.title',
    bodyKey = 'bonusCabinet.cancelBonus.body',
    buttonKey = 'bonusCabinet.cancelBonus.cancelOperation',
  } = useZustandModalStore(getModalAdditionalInfoSelector);

  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);
  const close = useZustandModalStore(setModalInfoAction);

  const onClose = () => {
    if (previousModalType) {
      closeAndOpen(previousModalType);

      return;
    }

    close(ModalTypes.Unknown);
  };

  const handleCancelOperation = () => {
    sendCommand(
      '23x',
      {
        operationId,
        requestId: ERequestIds.CancelOperation,
      },
      true
    );
    onClose();
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title: getText(titleKey),
        transparent: true,
      }}
      buttonClose={onClose}
    >
      <>
        <div className={styles.contentSubTitle}>
          <Text idT={bodyKey} />
        </div>

        <ComponentKitBaseButton
          text={buttonKey}
          size="--button-full"
          buttonHeight="--button-height-full"
          color="primary"
          handleClick={handleCancelOperation}
          centreText
        />
      </>
    </ModalLayout>
  );
};

export default ClaimConfirmation;
