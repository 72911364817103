import cln from 'classnames';
import { useGetWeeklyBonus } from 'decryption_protocol_hooks';
import { ReturnTypeWeeklyBonus } from 'decryption_protocol/dist/lib/store/operations/entities/vipClub/types';

import BonusCardLayoutComponentKit from 'components/ui/KitComponents/BonusCardLayoutComponentKit';

import { APP_TYPE, IS_FSWIN } from 'constants/ui';

import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { getNextSaturday } from 'utils/date';

import styles from './WeeklyCard.module.css';

const WeeklyCard = () => {
  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );
  const weekly = useGetWeeklyBonus({
    isAuth,
    isConnectedApp,
  }) as null | ReturnTypeWeeklyBonus;

  return (
    <BonusCardLayoutComponentKit
      title="newBonuses.cards.weekly"
      description="newBonuses.cards.weeklyDescription"
      id={weekly?.id || -1}
      value={`${weekly?.amount || ''}`}
      endTimestamp={weekly?.endData || getNextSaturday()}
    >
      <img
        src={`/icons-elon/bonuses/cards/${APP_TYPE}/weekly.png`}
        className={cln(styles.wrapperWeeklyCard, {
          [styles.imgHeight]: IS_FSWIN,
        })}
      />
    </BonusCardLayoutComponentKit>
  );
};

export default WeeklyCard;
