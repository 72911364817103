import { useEffect, useRef, useState } from 'react';
import cln from 'classnames';

import { ReactComponent as CloseSvg } from 'assets/base/close.svg';
import { NotificationTypeIcon } from '../NotificationTypeIcon';
import { Text } from 'decryption_component-kit';

import { Notification } from 'stores/notification/types';
import { removeNotificationAction } from 'stores/notification/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';

import styles from './NotificationView.module.css';
import animation from 'styles/animation.module.css';

const NotificationView = (props: Notification) => {
  const { text, type, uid } = props;

  const removeNotification = useZustandNotificationStore(
    removeNotificationAction
  );

  const handleRemoveNotification = () => {
    removeNotification(uid);
  };

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const [isHide, setHideState] = useState(false);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setHideState(true);
    }, 4000);
  }, []);

  useEffect(() => {
    if (isHide) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      setTimeout(() => {
        handleRemoveNotification();
      }, 1000);
    }
  }, [isHide]);

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    []
  );

  return (
    <div
      className={cln(styles.wrapperNotification, {
        [animation.animationMoveToLeftNotification]: !isHide,
        [animation.animationMoveToRightNotification]: isHide,
      })}
    >
      <div className={styles.row}>
        <NotificationTypeIcon type={type} />

        <div className={styles.text}>
          <Text idT={text} />
        </div>
      </div>

      <CloseSvg
        className={styles.closeIcon}
        onClick={handleRemoveNotification}
      />
    </div>
  );
};

export default NotificationView;
