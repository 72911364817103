import { useRef, useState } from 'react';
import cln from 'classnames';

import { ReactComponent as WalletSvg } from 'assets/base/wallet.svg';

import AppSwitcher from '../CasinoDrawer/components/AppSwitcher';
import Input from 'components/ui/Input/Base';
import ListSports from './components/ListSports';
import CategorySportInfo from './components/CategorySportInfo';
import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { BETTING_EVENT_TYPES } from './constants';

import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { TabTypes } from './types';
import { ModalTypes } from 'stores/modal/types';

import styles from './BettingDrawer.module.css';
import ComponentKitBaseInput from 'components/ui/KitComponents/Inputs/ComponentKitBaseInput';
import { DrawerLayout } from 'decryption_component-kit';

const BettingDrawer = () => {
  const [activeTab, setActiveTab] = useState(TabTypes.Prematch);

  const [activeSportId, setActiveSportId] = useState<number | null>(null);

  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);

  const drawerElement = useRef<HTMLDivElement | null>(null);

  const [search, setSearch] = useState<string>('');

  const handleChangeSearch = (txt: string) => {
    setSearch(txt);
  };

  const handleChooseSportId = (id: number | null) => () => {
    const newSportId = activeSportId === id ? null : id;

    drawerElement.current?.scrollTo({
      top: 0,
    });

    setActiveSportId(newSportId);
  };

  const openModal = (t: ModalTypes) => () => {
    closeAndOpen(t);
  };

  return (
    <DrawerLayout
      className={cln(styles.wrapperBettingDrawer, {
        [styles.disabledScroll]: activeSportId,
      })}
      ref={drawerElement}
    >
      {!activeSportId && (
        <>
          <AppSwitcher />

          <ComponentKitBaseButton
            size="--button-full"
            buttonHeight="--button-height-full"
            text="drawers.casino.depositBtn"
            handleClick={openModal(
              !isAuth ? ModalTypes.SignIn : ModalTypes.MoneyOperations
            )}
            startIcon={<WalletSvg />}
            color="primary"
            classes={styles.marginBottom}
            centreText
          />
        </>
      )}
      <ComponentKitBaseInput
        placeholder="T.search"
        onChange={handleChangeSearch}
        size="full"
        classes={styles.marginBottom}
      />

      <ComponentKitTabs
        activeValue={activeTab}
        data={BETTING_EVENT_TYPES}
        onChange={setActiveTab}
        classes={cln(styles.marginBottom, styles.tabs)}
      />

      <ListSports
        handleChooseSport={handleChooseSportId}
        isShortView={Boolean(activeSportId)}
        chooseSportId={activeSportId}
        isPrematch={activeTab === TabTypes.Prematch}
      />

      {activeSportId && activeTab === TabTypes.Prematch && (
        <CategorySportInfo
          resetSportId={handleChooseSportId(null)}
          sportId={activeSportId}
          isPrematch
        />
      )}
    </DrawerLayout>
  );
};

export default BettingDrawer;
