import { useMemo } from 'react';
import {
  Payment,
  PaymentChannelType,
} from 'decryption_protocol/dist/lib/store/user/types';

import { ReactComponent as WalletSvg } from 'assets/base/wallet.svg';
import { Text } from 'decryption_component-kit';
import HOCImageWithDefault from 'components/HOCs/HOCImageWithDefault';
import SkeletLoader from 'components/ui/Loaders/SkeletLoader';

import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { Steps } from 'stores/money/payments/types';

import { BASE_IMG_URL } from 'constants/image';

import styles from './List.module.css';

type Props = {
  data: Payment[];
  setActiveMethod: (payment: Payment) => void;
  classes?: string;
};

const List = ({ data, setActiveMethod, classes }: Props) => {
  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const handleSetMethodId = (payment: Payment) => () => {
    setStep(Steps.Amount);
    setActiveMethod(payment);
  };

  const [fiat, crypto] = useMemo(() => {
    const fiat = data.filter((m) => !m.isCrypto);
    const cripto = data.filter((m) => m.isCrypto);

    return [fiat, cripto];
  }, [data]);

  return (
    <div className={classes}>
      {!!fiat.length && (
        <>
          <div className={styles.subTitle}>
            <Text idT="walletPopUp.fiat" />
          </div>
          <div className={styles.listWrapper}>
            {!fiat.length &&
              [1, 2, 3, 4, 5].map((l) => (
                <div className={styles.loaderItem} key={l}>
                  <SkeletLoader />
                </div>
              ))}

            {fiat.map((i) => (
              <div
                key={`${i.id}_${i.channelName}`}
                className={styles.itemWrapper}
                onClick={handleSetMethodId(i)}
              >
                <HOCImageWithDefault
                  src={`${BASE_IMG_URL}/payments/${
                    i.id === PaymentChannelType.InrDeposit
                      ? `${i.id}_${i.channelName.replace(' ', '_')}`
                      : i.id
                  }.svg`}
                  classes={styles.defaultImg}
                >
                  <WalletSvg className={styles.defaultImg} />
                </HOCImageWithDefault>

                <div className={styles.name}>
                  <Text idT={i.channelName} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {!!crypto.length && (
        <>
          <div className={styles.subTitle}>
            <Text idT="walletPopUp.cripto" />
          </div>
          <div className={styles.listWrapper}>
            {!crypto.length &&
              [1, 2, 3, 4, 5].map((l) => (
                <div className={styles.loaderItem} key={l}>
                  <SkeletLoader />
                </div>
              ))}

            {crypto.map((i) => (
              <div
                key={`${i.id}_${i.channelName}`}
                className={styles.itemWrapper}
                onClick={handleSetMethodId(i)}
              >
                <HOCImageWithDefault
                  src={`${BASE_IMG_URL}/payments/${
                    i.id === PaymentChannelType.InrDeposit
                      ? `${i.id}_${i.channelName.replace(' ', '_')}`
                      : i.id
                  }.svg`}
                  classes={styles.defaultImg}
                >
                  <WalletSvg className={styles.defaultImg} />
                </HOCImageWithDefault>

                <div className={styles.name}>
                  <Text idT={i.channelName} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default List;
