import cln from 'classnames';
import { useGetRakeBack } from 'decryption_protocol_hooks';
import { ReturnTypeRakeBake } from 'decryption_protocol/dist/lib/store/operations/entities/vipClub/types';

import BonusCardLayoutComponentKit from 'components/ui/KitComponents/BonusCardLayoutComponentKit';

import { APP_TYPE, IS_FSWIN } from 'constants/ui';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

import styles from './RakeBackCard.module.css';

const RakeBackCard = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const rakebackInfo = useGetRakeBack({
    isConnectedApp,
  }) as null | ReturnTypeRakeBake;

  return (
    <BonusCardLayoutComponentKit
      title="newBonuses.cards.rakeback"
      description="newBonuses.cards.rakebackDescription"
      id={rakebackInfo?.id || -1}
      value={`${rakebackInfo?.rakeback || 0}`}
      endTimestamp={
        !rakebackInfo?.endTime || !rakebackInfo?.isBoost
          ? undefined
          : rakebackInfo?.endTime
      }
      withConfirmation={rakebackInfo?.isBoost}
    >
      <img
        src={`/icons-elon/bonuses/cards/${APP_TYPE}/rakeback${
          rakebackInfo?.isBoost ? '-boost' : ''
        }.png`}
        className={cln(styles.wrapperRakeBackCard, {
          [styles.imgHeight]: IS_FSWIN,
        })}
      />
    </BonusCardLayoutComponentKit>
  );
};

export default RakeBackCard;
