import { BonusCardLayout, useTranslate } from 'decryption_component-kit';
import { useTransportControllers } from 'decryption_protocol_hooks';
import { BonusCardLayoutProps } from 'decryption_component-kit/dist/lib/components/Layouts/BonusCardLayout/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import useCountDownTranslates from 'hooks/useCountDownTranslates';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

type Props = Omit<
  BonusCardLayoutProps & {
    id: number;
  },
  'handleClaim' | 'buttonText' | 'counterProps'
> & {
  withConfirmation?: boolean;
};

const BonusCardLayoutComponentKit = (props: Props) => {
  const { children, id, title, description, withConfirmation = false } = props;

  const { getText, name } = useTranslate(title, '');

  const { sendCommand } = useTransportControllers();

  const countDownPropsNames = useCountDownTranslates();

  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);

  const handleClaim = () => {
    if (withConfirmation) {
      closeAndOpen(ModalTypes.ClaimConfirmation, {
        previousModalType: ModalTypes.Bonuses,
        id,
        titleKey: 'popUp.claimBonus.title',
        bodyKey: 'popUp.claimBonus.rakeback',
        buttonKey: 'popUp.claimBonus.claim',
      });

      return;
    }

    sendCommand(
      '23x',
      {
        operationId: id,
        requestId: ERequestIds.CancelOperation,
      },
      true
    );
  };

  return (
    <BonusCardLayout
      {...props}
      title={name}
      description={getText(description)}
      handleClaim={handleClaim}
      buttonText={getText('vipClub.rakeback.claim')}
      counterProps={{ names: countDownPropsNames }}
    >
      {children}
    </BonusCardLayout>
  );
};

export default BonusCardLayoutComponentKit;
