import React, { useState } from 'react';

import { ReactComponent as CloseEyeSvg } from 'assets/base/close-eye.svg';
import { ReactComponent as OpenEyeSvg } from 'assets/base/open-eye.svg';

import { DefaultInputType, InputBaseProps } from '../Base/types';
import { DEFAULT_INPUT_VALUE } from '../Base/constant';
import { composeValidations, isPassword } from 'utils/validation';
import Input from '../../KitComponents/Inputs/ComponentKitBaseInput';

const PasswordInput = (props: InputBaseProps) => {
  const [inputType, setInputType] = useState<'text' | 'password'>('password');

  const [passwordInfo, setPasswordInfo] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const validateValuePassword = (value: string) => {
    const { onChange } = props;
    const errorText = composeValidations([
      isPassword({
        value,
        errorText: ['errors.passwordMin', 'errors.passwordMax'],
      }),
    ]);

    setPasswordInfo({
      value,
      errorText,
    });

    if (errorText) {
      onChange?.('');

      return;
    }

    onChange?.(value);
  };

  return (
    <Input
      {...props}
      type={inputType}
      errorText={passwordInfo.errorText}
      onChange={validateValuePassword}
    >
      {inputType === 'text' ? (
        <OpenEyeSvg onClick={() => setInputType('password')} />
      ) : (
        <CloseEyeSvg onClick={() => setInputType('text')} />
      )}
    </Input>
  );
};

export default PasswordInput;
