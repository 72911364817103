import { IS_DEVELOPMENT_MODE, IS_VERTICAL, IS_WEBP } from 'constants/ui';

const BETTING_PATH_NAME =
  process.env.NODE_ENV === 'production'
    ? '/Scripts/brospis[language]'
    : `${window.location.origin}/mock/betting/brospis[language].uu`;

const CASINO_CATEGORY_PATH =
  process.env.NODE_ENV !== 'production'
    ? ['/mock/casino/bcasconf_2.uu', '/mock/casino/bcasconf_1.uu']
    : ['/Scripts/bcasconf_2', '/Scripts/bcasconf_1'];

export const SETTINGS = {
  appType: Number(process.env?.REACT_APP_TYPE) || -2,
  authFrameUrl: 'https://googlemetrics.com/eljk3jd8j3kl98kl37jsnk3k/',
  referralBaseUrl: 'https://data.azufront.com/ref_service', // TODO MOVE TO CONFIG
  isDevMode: IS_DEVELOPMENT_MODE,
  casinoSettings: {
    casinoCategoryUrls: CASINO_CATEGORY_PATH,
    isVertical: true, //IS_VERTICAL && IS_DEVELOPMENT_MODE
    customPath: 'vertical_2/',
    webp: IS_WEBP,
  },
  bettingSettings: {
    pathname: BETTING_PATH_NAME,
  },
};
