import AvailableBonuses from '../AvailableBonuses';
import BKashFast from '../BKashFast';
import DefaultDeposit from '../DefaultDeposit';
import NagadApiFast from '../NagadApiFast';
import Rocket from '../Rocket';
import BKash from '../BKash';
import Nagad from '../Nagad';
import QrForm from '../QrForm';
import TurkishAnindaHavale from '../Turkish/TurkishAnindaHavale';
import TurkishPapara from '../Turkish/Papara';
import TurkishPayFix from '../Turkish/TurkishPayFix';
import TurkishHavaleHera from '../Turkish/TurkishHavaleHera';
import TurkishCepBank from '../Turkish/TurkishCepBank';
import VipHavale from '../Turkish/VipHavale';
import TurkishMpayMethods from '../Turkish/TurkishMpayMethods';
import TurkishMefete from '../Turkish/TurkishMefete';
import DebitCredit from '../Turkish/DebitCredit';
import TurkishExpressHavale from '../Turkish/TurkishExpressHavale';
import MoneticsBkashOrNagad from '../Monetics/MoneticsBkashOrNagad';
import WellexPayNagadBkash from '../WellexPay/NagadAndBKash';
import RubCreditCard from '../Rubels/CreditCard';

import { MoneticPaymentTypes } from 'stores/money/forms/types';

import {
  CashXMethods,
  MPayMethods,
  PaymentChannelType,
  RublesMethods,
  WellexPayMethods,
} from 'decryption_protocol/dist/lib/store/user/types';

import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import {
  COINPAY_CRYPTO,
  MONETICS_DEPOSIT,
  MPAY_METHODS,
  MPAY_METHODS_WITH_KIMLIK,
  WELLEXPAY,
  XOOM_PAY,
  RUBLES,
} from 'decryption_protocol/dist/lib/store/user/constants';

import styles from './DepositSkelet.module.css';
import InrDeposit from '../Inr';
import InrDepositOctoPay from '../Inr/Octopays';
import PKRMonetics from '../PKRMonetics';

type Props = {
  activeChannelId: PaymentChannelType | null;
  limit: { min: number; max: number } | null;
};

const DepositSkeleton = (props: Props) => {
  const { activeChannelId, limit } = props;

  console.log('🚀 ~ activeChannelId:', activeChannelId);

  if (!activeChannelId || limit === null) {
    return null;
  }

  if (COINPAY_CRYPTO.includes(activeChannelId)) {
    return (
      <div className={styles.depositSkeletFormWrapper}>
        <QrForm activeChannelId={activeChannelId} min={limit.min} />
      </div>
    );
  }

  return (
    <div className={styles.depositSkeletFormWrapper}>
      <AvailableBonuses />

      {(activeChannelId === PaymentChannelType.PKRCashmaal ||
        activeChannelId === PaymentChannelType.PKRJazzcash ||
        activeChannelId === PaymentChannelType.PKREasypaisa) && (
        <PKRMonetics limit={limit} channelId={activeChannelId} />
      )}

      {activeChannelId === PaymentChannelType.INRUpiFast && (
        <InrDepositOctoPay limit={limit} />
      )}

      {activeChannelId === PaymentChannelType.InrDeposit && (
        <InrDeposit
          limit={limit}
          key={activeChannelId}
          channelId={activeChannelId}
        />
      )}

      {/* @ts-ignore */}
      {RUBLES.includes(activeChannelId) && (
        <RubCreditCard
          limit={limit}
          key={activeChannelId}
          channelId={activeChannelId as RublesMethods}
        />
      )}

      {/* @ts-ignore */}
      {(WELLEXPAY.includes(activeChannelId) ||
        XOOM_PAY.includes(activeChannelId)) && (
        <WellexPayNagadBkash
          channelId={activeChannelId as WellexPayMethods}
          limit={limit}
          key={activeChannelId}
        />
      )}

      {MPAY_METHODS.includes(activeChannelId) && (
        <TurkishMpayMethods
          limit={limit}
          activeChannelId={activeChannelId as MPayMethods}
          key={activeChannelId}
        />
      )}

      {MPAY_METHODS_WITH_KIMLIK.includes(activeChannelId) && (
        <TurkishMpayMethods
          limit={limit}
          withKimlik
          activeChannelId={activeChannelId as MPayMethods}
          key={activeChannelId}
        />
      )}

      {activeChannelId === PaymentChannelType.TurkishCreditDebit && (
        <DebitCredit limit={limit} key={activeChannelId} />
      )}

      {activeChannelId === PaymentChannelType.TurkishExpressHavale && (
        <TurkishExpressHavale limit={limit} key={activeChannelId} />
      )}
      {activeChannelId === PaymentChannelType.TurkishPapara && (
        <TurkishPapara limit={limit} key={activeChannelId} />
      )}

      {activeChannelId === PaymentChannelType.TurkishCepBank && (
        <TurkishCepBank limit={limit} key={activeChannelId} />
      )}
      {activeChannelId === PaymentChannelType.TurkishMefete && (
        <TurkishMefete limit={limit} key={activeChannelId} />
      )}

      {activeChannelId === PaymentChannelType.VipHavale && (
        <VipHavale limit={limit} key={activeChannelId} />
      )}

      {/* 
        activeChannelId === PaymentChannelType.PaparaMPay */}
      {activeChannelId === PaymentChannelType.TurkishPayFix && (
        <TurkishPayFix
          limit={limit}
          activeChannelId={activeChannelId}
          key={activeChannelId}
        />
      )}

      {activeChannelId === PaymentChannelType.AnindaHavale && (
        <TurkishAnindaHavale limit={limit} key={activeChannelId} />
      )}

      {activeChannelId === PaymentChannelType.HavaleHera && (
        <TurkishHavaleHera limit={limit} key={activeChannelId} />
      )}

      {(activeChannelId === PaymentChannelType.BKashFast ||
        activeChannelId === PaymentChannelType.ApayBkash) && (
        <BKashFast
          limit={limit}
          key={activeChannelId}
          channelId={activeChannelId}
        />
      )}

      {MONETICS_DEPOSIT.includes(activeChannelId) && (
        <MoneticsBkashOrNagad
          limit={limit}
          channelId={activeChannelId as MoneticPaymentTypes}
          key={activeChannelId}
        />
      )}

      {/* ApiNagad */}
      {/* AutoBkash */}
      {(activeChannelId === PaymentChannelType.NagadApi ||
        activeChannelId === PaymentChannelType.ApayNagad) && (
        <NagadApiFast
          limit={limit}
          key={activeChannelId}
          channelId={activeChannelId}
        />
      )}

      {(activeChannelId === PaymentChannelType.EasypaisaFast ||
        activeChannelId === PaymentChannelType.Easypaisa ||
        activeChannelId === PaymentChannelType.JazzCash) && (
        <NagadApiFast
          limit={limit}
          key={activeChannelId}
          channelId={activeChannelId}
        />
      )}

      {/* Two steps */}
      {activeChannelId === PaymentChannelType.Rocket && (
        <Rocket limit={limit} key={activeChannelId} />
      )}
      {activeChannelId === PaymentChannelType.BKash && (
        <BKash limit={limit} key={activeChannelId} />
      )}
      {activeChannelId === PaymentChannelType.Nagad && (
        <Nagad limit={limit} key={activeChannelId} />
      )}

      {/* DEFAULT FORMs TODO: WELLExPAY BKASH AND NAGAD NEED TO ADD*/}
      {(activeChannelId === PaymentChannelType.VivaroBkash ||
        activeChannelId === PaymentChannelType.VivaroNagad) && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositBKash} // TODO it's not importatnt
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.TRC && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositTRC}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.ERC && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositERC}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.BEP && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositBEP}
          limit={limit}
          key={activeChannelId}
        />
      )}

      {activeChannelId === PaymentChannelType.PixPagsmile && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositPixPagsmile}
          limit={limit}
          key={activeChannelId}
        />
      )}

      {activeChannelId === PaymentChannelType.PagsmileWalletPicPay && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositPagsmileWalletPicPay}
          limit={limit}
          key={activeChannelId}
        />
      )}

      {activeChannelId === PaymentChannelType.Lottery && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositLottery}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.WalletAme && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositWalletAme}
          limit={limit}
          key={activeChannelId}
        />
      )}

      {activeChannelId === PaymentChannelType.PagsmileBoleto && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.PagsmileBoleto}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.PagsmileDepositExpress && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.PagsmileDepositExpress}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.OctopayRocket && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositOctopayRocket}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.OctopayNagad && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositOctopayNagad}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.OctopayBKash && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositOctopayBKash}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.OctopayUPay && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositOctopayUPay}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.Pix && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositPix}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.MPayCC && (
        <DefaultDeposit
          channelId={activeChannelId}
          requestId={ERequestIds.DepositMPay}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.NagadCashX && (
        <WellexPayNagadBkash
          channelId={activeChannelId as CashXMethods}
          limit={limit}
          key={activeChannelId}
        />
      )}
      {activeChannelId === PaymentChannelType.BkashCashX && (
        <WellexPayNagadBkash
          channelId={activeChannelId as CashXMethods}
          limit={limit}
          key={activeChannelId}
        />
      )}
    </div>
  );
};

export default DepositSkeleton;
