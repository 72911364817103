import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import cln from 'classnames';
import {
  useGetCasinoGameById,
  useTransportControllers,
} from 'decryption_protocol_hooks';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';
import { AdaptedGame } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import { ReactComponent as GiftSvg } from 'assets/base/bonusGift.svg';
import CasinoGame from 'components/CasinoComponents/CasinoGame';
import CountDownKit from 'components/ui/KitComponents/CountDownKit';
import ProgressWagering from '../../Progress/ProgressWagering';
import ButtonCancelBonus from 'components/ui/Buttons/CancelBonus';
import { Text, useTranslate } from 'decryption_component-kit';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { ACTIVATED_INFO, ACTIVATE_INFO } from './constants';

import { ERouteNames } from 'Routes/types';
import { BaseProps, EFreeSpinBonusStatusView } from './types';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { setDepositBonusIdForActivateAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

import { getFromLocalStorage } from 'utils/localstorage';
import { MANUAL_BET } from 'constants/localstoreKeys';

import styles from './FreeSpin.module.css';

const FreeSpin = (props: BaseProps) => {
  const {
    gameId,
    classes,
    endActivationTime = 0,
    isPack = false,
    isActive = false,
    operationId,
    rolled = 0,
    fullRolled = 0,
    percent = 0,
    spins,
    validFrom = 0,
    validUntil = 0,
  } = props;

  const { getText } = useTranslate('', '');

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const game = useGetCasinoGameById({
    gameId,
    isMobile,
    isConnectedApp,
  }) as AdaptedGame | null;

  const navigate = useNavigate();

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const setBonusId = useZustandFormsStore(setDepositBonusIdForActivateAction);

  const { sendCommand } = useTransportControllers();

  const [freeSpinViewInfo, setFreeSpinViewInfo] = useState({
    statusSpinCard: EFreeSpinBonusStatusView.Unknown,
    time: 0,
  });

  const { statusSpinCard, time } = freeSpinViewInfo;

  const handleSetFreeSpinCardStatus = (data: {
    isActive: boolean;
    endActivationTime: number;
    validFrom: number;
    validUntil: number;
  }) => {
    const { isActive, endActivationTime, validFrom, validUntil } = data;

    const { statusSpinCard } = freeSpinViewInfo;

    if (!isActive) {
      if (statusSpinCard !== EFreeSpinBonusStatusView.Available) {
        setFreeSpinViewInfo({
          statusSpinCard: EFreeSpinBonusStatusView.Available,
          time: endActivationTime,
        });
      }

      return;
    }

    const currentTimeStamp = new Date().getTime();

    if (currentTimeStamp < validFrom) {
      if (statusSpinCard !== EFreeSpinBonusStatusView.ActiveFromTime) {
        setFreeSpinViewInfo({
          statusSpinCard: EFreeSpinBonusStatusView.ActiveFromTime,
          time: validFrom,
        });
      }

      return;
    }

    if (currentTimeStamp < validUntil) {
      if (fullRolled) {
        setFreeSpinViewInfo({
          statusSpinCard: EFreeSpinBonusStatusView.Rolling,
          time: endActivationTime,
        });
      }

      if (statusSpinCard !== EFreeSpinBonusStatusView.ActiveForSpins) {
        setFreeSpinViewInfo({
          statusSpinCard: EFreeSpinBonusStatusView.ActiveForSpins,
          time: validUntil,
        });
      }

      return;
    }

    if (fullRolled) {
      setFreeSpinViewInfo({
        statusSpinCard: EFreeSpinBonusStatusView.Rolling,
        time: endActivationTime,
      });
    }
  };

  const callbackEndTime = () => {
    handleSetFreeSpinCardStatus({
      isActive,
      endActivationTime,
      validFrom,
      validUntil,
    });
  };

  const handleBetManual = () => {
    const manualBetSum = getFromLocalStorage(MANUAL_BET);

    if (manualBetSum) {
      const data = {
        operationId,
        amount: manualBetSum,
        requestId: 1,
      };

      sendCommand('43x', data);

      return;
    }
    // TODO active bonus ID
    setModal(ModalTypes.MoneyOperations);
    setBonusId(operationId);
  };

  useEffect(() => {
    if (freeSpinViewInfo.statusSpinCard !== EFreeSpinBonusStatusView.Unknown) {
      return;
    }

    handleSetFreeSpinCardStatus({
      isActive,
      endActivationTime,
      validFrom,
      validUntil,
    });
  }, [isActive, validFrom, validUntil, freeSpinViewInfo]);

  useEffect(() => {
    if (
      freeSpinViewInfo.statusSpinCard === EFreeSpinBonusStatusView.Unknown ||
      freeSpinViewInfo.statusSpinCard === EFreeSpinBonusStatusView.Rolling ||
      !fullRolled
    ) {
      return;
    }

    handleSetFreeSpinCardStatus({
      isActive,
      endActivationTime,
      validFrom,
      validUntil,
    });
  }, [isActive, validFrom, validUntil, freeSpinViewInfo, fullRolled]);

  const handleNavigate = () => {
    const pathname =
      statusSpinCard === EFreeSpinBonusStatusView.ActiveForSpins
        ? ERouteNames.CasinoGame.replace(':gameId', `${gameId}`)
        : ERouteNames.CasinoCategories.replace(
            ':categoryId',
            `${ECasinoCategoryIds.Slots}`
          ).replace(':subcategoryId', 'all');

    setModal(ModalTypes.Unknown);
    navigate(pathname);
  };

  const info = isActive ? ACTIVATED_INFO : ACTIVATE_INFO;

  return (
    <div className={cln(styles.wrapperFreeSpins, classes)}>
      <div
        className={cln(styles.wrapperFreeSpinsContent, {
          [styles.disabledMarginB]: isPack,
        })}
      >
        <div className={styles.wrapperFreeSpinInfo}>
          <div className={cln(styles.title, styles.marginB)}>
            <Text idT="bonusCabinet.spins.title" specValue={`${spins}`} />
          </div>
          {info.map(({ text, key }) => (
            <div className={styles.row} key={text}>
              <Text idT={text} />:<span>{props[key] || '-1'}</span>
            </div>
          ))}
          {spins && (isActive || isPack) && (
            <div className={styles.spinsButton}>
              <Text
                idT="bonusCabinet.spins.countSpins"
                specValue={`${spins}`}
              />
            </div>
          )}
        </div>

        <div className={styles.wrapperCasinoGame}>
          {game && (
            <div className={styles.gameImgContainer}>
              <CasinoGame {...game} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.desktopWrapper}>
        {!isActive && !isPack && (
          <div className={styles.row}>
            <ComponentKitBaseButton
              color="primary"
              size="--button-xxl"
              buttonHeight="--button-height-l"
              handleClick={handleBetManual}
              startIcon={<GiftSvg />}
              centreText
              text={getText('bonusCabinet.spins.getBonus')}
              classes={styles.button}
            />

            {statusSpinCard !== EFreeSpinBonusStatusView.Rolling &&
              isMobile &&
              time && (
                <CountDownKit
                  endTimestamp={time}
                  wrapperClasses={styles.countDownClasses}
                  timeBlockClasses={styles.timeBlockClasses}
                  timeClasses={styles.timeClasses}
                  callbackEndTime={callbackEndTime}
                />
              )}
            {!isPack &&
              statusSpinCard !== EFreeSpinBonusStatusView.Rolling &&
              !isMobile && (
                <CountDownKit
                  endTimestamp={time}
                  wrapperClasses={styles.countDownClasses}
                  timeBlockClasses={styles.timeBlockClasses}
                  timeClasses={styles.timeClasses}
                  callbackEndTime={callbackEndTime}
                />
              )}
          </div>
        )}

        {!spins && isActive && (
          <ProgressWagering
            rolled={rolled}
            fullRolled={fullRolled}
            percent={percent}
            classes={styles.progressWrapper}
          />
        )}

        {isActive && (
          <div className={styles.rowButtons}>
            <ComponentKitBaseButton
              color="primary"
              size="--button-xxl"
              buttonHeight="--button-height-l"
              handleClick={handleNavigate}
              centreText
              disabled={
                statusSpinCard === EFreeSpinBonusStatusView.ActiveFromTime
              }
              text={getText('bonusCabinet.spins.play')}
              classes={styles.playBtn}
            />

            <ButtonCancelBonus
              nameBonusKey="bonusCabinet.spins.countSpins"
              operationId={operationId}
              classes={styles.cancelBonusBtn}
              isImg
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FreeSpin;
