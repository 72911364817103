import { ReactComponent as SearchSvg } from 'assets/base/search.svg';
import { ReactComponent as CloseSvg } from 'assets/base/close.svg';

import Input from 'components/ui/Input/Base';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './ProviderSearch.module.css';

type Props = {
  setSearchProviderValue: (d: string) => void;
};

const ProviderSearch = ({ setSearchProviderValue }: Props) => {
  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const onClose = () => {
    setModal(ModalTypes.Unknown);
  };

  return (
    <div className={styles.wrapperProviderSearch}>
      <Input
        placeholder="moreThenThreeLetters"
        size="full"
        startIcon={<SearchSvg className={styles.searchIconInput} />}
        onChange={setSearchProviderValue}
        classes={styles.searchInput}
      />

      <CloseSvg className={styles.closeIcon} onClick={onClose} />
    </div>
  );
};

export default ProviderSearch;
