import { ERouteNames } from 'Routes/types';

import { ReactComponent as BurgerSvg } from 'assets/base/burger.svg';
import { ReactComponent as LotterySvg } from 'assets/base/lottery.svg';
import { ReactComponent as BettingSvg } from 'assets/nav/betting.svg';
import { ReactComponent as CasinoSvg } from 'assets/nav/casino.svg';
import { ReactComponent as BonusesSvg } from 'assets/nav/bonuses.svg';
import { ReactComponent as BettingLive } from 'assets/nav/bettingLive.svg';
import { ReactComponent as MyBets } from 'assets/nav/myBets.svg';

import { ModalTypes } from 'stores/modal/types';

import { NavigationButton } from './types';
import { IS_DEVELOPMENT_MODE } from 'constants/ui';
import { LotteryTypes } from 'stores/lottery/types';

export const BETTING_NAVIGATION_BAR: NavigationButton[] = [
  {
    icon: <BurgerSvg />,
    text: 'navigationBar.bettingMenu',
    isPopUp: true,
    modalType: ModalTypes.DrawerBettingModal,
  },
  {
    icon: <BettingLive />,
    text: 'navigationBar.bettingLive',
    // @ts-ignore
    path: !IS_DEVELOPMENT_MODE ? '/betting/live' : ERouteNames.BettingLive,
  },
  {
    icon: <BettingSvg />,
    text: 'navigationBar.bettingPrematch',
    // @ts-ignore
    path: !IS_DEVELOPMENT_MODE ? '/betting/sport' : ERouteNames.BettingPrematch,
  },
  {
    icon: <CasinoSvg />,
    text: 'navigationBar.casino',
    path: ERouteNames.CasinoHome,
  },
  {
    icon: <MyBets />,
    text: 'navigationBar.myBets',
    isAuthDependes: true,

    // @ts-ignore --- BECAUSE OF FRAME
    path: IS_DEVELOPMENT_MODE ? undefined : '/profile/history-operations',
    isPopUp: IS_DEVELOPMENT_MODE ? true : undefined,
    modalType: IS_DEVELOPMENT_MODE ? ModalTypes.InGameTickets : undefined,
    // ModalTypes.BettingTransaction,
  },
];

export const CASINO_NAVIGATION_BAR: NavigationButton[] = [
  {
    icon: <BurgerSvg />,
    text: 'navigationBar.casinoMenu',
    isPopUp: true,
    modalType: ModalTypes.DrawerCasinoModal,
  },

  {
    icon: <CasinoSvg />,
    text: 'navigationBar.casino',
    path: ERouteNames.CasinoHome,
  },
  {
    icon: <BettingSvg />,
    text: 'navigationBar.bettingPrematch',
    path: IS_DEVELOPMENT_MODE
      ? ERouteNames.BettingPrematch
      : ERouteNames.FrameBetting,
  },

  // IS_DEVELOPMENT_MODE
  //   ?
  {
    icon: <LotterySvg />,
    text: 'navigationBar.promoCode',
    isAuthDependes: true,
    path: ERouteNames.PromoCode,
  },
  // : {
  //     icon: <LotterySvg />,
  //     text: 'drawers.casino.lottery',
  //     path: ERouteNames.Lottery.replace(
  //       ':type',
  //       LotteryTypes[0]
  //     ) as ERouteNames,
  //   },
  {
    icon: <BonusesSvg />,
    text: 'navigationBar.bonus',
    isPopUp: true,
    modalType: ModalTypes.Bonuses,
    isAuthDependes: true,
  },
];
