import { useTransportControllers } from 'decryption_protocol_hooks';
import { ENotifications } from 'decryption_protocol/dist/lib/store/notifications/types';

import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import { Currency } from 'stores/forms/signUp/types';

import {
  HandleAdditionalUserInfoProps,
  HandleBillingAddressResponse,
  HandleCasinoLoyaltyFrameResponse,
  HandleRegistrationResponse,
  HandleRunCasinoGameProps,
  HandleTokenUpdate,
  RecoveryPasswordStatus,
  SetNewPasswordStatus,
  UpdatePasswordType,
  UpdateUserInfo,
} from './types';
import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import { useZustandRequestStore } from 'stores/request/store';

import { removeFromLocalStorage, setToLocalStorage } from 'utils/localstorage';
import { TOKEN } from 'constants/localstoreKeys';
import { useZustandMarketingTabsStore } from 'stores/marketing/store';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { useZustandUserStore } from 'stores/user/personal/store';
import { useZustandBonusPackStore } from 'stores/user/bonusPack/store';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import {
  EIconType,
  HandleDepositQrFormResponse,
  ParseResponseType,
} from 'decryption_protocol/dist/lib/service/moneyResponse/types';
import { BalanceInfo } from 'decryption_protocol/dist/lib/store/user/entities/balance/types';
import { useZustandModalStore } from 'stores/modal/store';
import { ModalTypes } from 'stores/modal/types';
import { useZustandProfileStore } from 'stores/forms/profile';
import { UpdateStatus } from 'stores/forms/profile/types';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { AdaptedBonusInfo, BonusPack } from 'hooks/useBonusPack';
import { useZustandFrameLoyaltyStore } from 'stores/user/frameLoyalty/store';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { Steps } from 'stores/money/payments/types';
import { useZustandNotificationStore } from 'stores/notification/store';
import { ENotificationType } from 'stores/notification/types';
import {
  MOBILE_POST_MASSAGE_SIGN_IN,
  MOBILE_SET_KEYCHAIN_DATA,
} from 'constants/transport';
import { ShowMoneyPopUpState } from 'stores/user/balance/types';

import {
  UserInfo,
  EPaymentLaunch,
  PaymentChannelType,
} from 'decryption_protocol/dist/lib/store/user/types';
import { getActivePaymentInfoSelector } from 'stores/money/payments/selectors';
import { IS_BONUS_SHOULD_OPEN } from 'constants/marketingTags';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';

export const decodeResponse = (key: string, result: any) => {
  const { requestId = null } = result || {};

  if (requestId !== null) {
    useZustandRequestStore.getState().removeKey(requestId);
  }

  switch (key) {
    case 'casinoLoyaltyUrlSuccess':
      return handleLoyaltySuccess(result);
    case 'update-token':
      return handleTokenUpdate(result);
    case 'runCasinoGame':
      return runCasinoGame(result);
    case 'additionalUserInfo':
      return handleAdditionalUserInfo(result);
    case 'bonus-pack-available':
      return handleBonusPackInfo(result);
    case 'backOffice':
      return handleSetRegistrationCurrencies(result);

    case 'requested': // this is callback that requests has been sended and adapting - TODO remove this when all hooks will be rewritten
      return;

    case 'authorizationFail':
      return handleAuthResponseFail();
    case 'authorizationSuccess':
      return handleAuthResponseSuccess(result);
    case 'updateBalance':
      return updateBalance(result);
    case 'registration':
      return registrationResponse(result);
    case 'password':
      return handleUpdatePassword(result);
    case 'recoveryPassword':
      return handleRecoveryPassword(result);
    case 'setNewPassword':
      return handleSetNewPassword(result);
    case 'assignPhoneResponse':
    case 'assignEmailResponse':
      return assignUserInfoResponse(result);
    //
    case 'deposit':
    case 'withdraw':
      return handleWalletResponse(result);

    case 'deposit-cripto-qr-form':
      return handleCriptoWalletResponse(result);

    case 'billingAddress':
      return handleBillingAddressResponse(result);

    case 'notification':
      return handleNotification(result);
    default:
      console.warn('🚀 MESSAGE without handles', key);
      break;
  }
};
const handleSetRegistrationCurrencies = (result: Currency[]) => {
  useZustandRegistrationStore.getState().setCurrencies(result);

  useTransportControllers().sendCommand('34x', {});
  useTransportControllers().sendCommand('x0', {});
};

const runCasinoGame = (result: HandleRunCasinoGameProps) => {
  if (result.errorCode) {
    const { addNotification } = useZustandNotificationStore.getState();

    addNotification({
      uid: `casino_game_failed+${new Date().getTime()}`,
      text: 'errors.gameFailed',
      type: ENotificationType.Error,
    });

    return;
  }

  useZustandCasinoGamesStore.getState().setCasinoGameUrl(result.url);
};

const handleAuthResponseFail = () => {
  const { addNotification } = useZustandNotificationStore.getState();

  addNotification({
    uid: `auth_failed+${new Date().getTime()}`,
    text: 'errors.authFailed',
    type: ENotificationType.Error,
  });

  return;
};

const handleAuthResponseSuccess = async (result: UserInfo) => {
  const { isValidMobileApp, isRNMobileApp } =
    useZustandMarketingTabsStore.getState();

  // isMobileApp,
  const { login, clientId, email } = result;

  if (isValidMobileApp && isRNMobileApp) {
    useTransportControllers().sendCommand('41x', {
      tag: 'own_apk',
    });

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: MOBILE_POST_MASSAGE_SIGN_IN,
        payload: {
          email,
          clientId,
          login,
        },
      })
    );
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: MOBILE_SET_KEYCHAIN_DATA,
        payload: {
          key: TOKEN,
          value: result.token,
        },
      })
    );
  }

  const { showMoneyPopUpState, setShowPopUpState } =
    useZustandBalanceStore.getState();

  if (showMoneyPopUpState === ShowMoneyPopUpState.Unknown) {
    setShowPopUpState(ShowMoneyPopUpState.WaitingBalance);
  }

  const modalType = IS_BONUS_SHOULD_OPEN
    ? ModalTypes.Bonuses
    : ModalTypes.Unknown;

  useZustandModalStore.getState().closeAndOpen(modalType);

  const useZustandLoaderStore = useZustandRequestStore.getState();

  useZustandLoaderStore.addKey(ERequestIds.RequestBalance);

  // set token to frame and remove frame from dom
  setToLocalStorage(TOKEN, result.token);

  useTransportControllers().endpoints?.setNewToken(result.token);

  useTransportControllers().sendCommand('x01', {
    requestId: ERequestIds.RequestBalance,
  });

  const userTag = useZustandMarketingTabsStore.getState().utmContent;

  if (userTag) {
    useTransportControllers().sendCommand('41x', { tag: userTag });
  }

  useTransportControllers().sendCommand('33x', {
    currencyId: result.currencyId,
  });

  useZustandUserStore.getState().setUserInfo(result);
};
// TODO:not implemented all types
const handleAdditionalUserInfo = (result: HandleAdditionalUserInfoProps) => {
  const { isAvailableBonusPack, userPermissions = null } = result;

  if (isAvailableBonusPack) {
    // TODO show modal
    useZustandBonusPackStore
      .getState()
      .setIsAvailableStatus(isAvailableBonusPack);

    useZustandModalStore.getState().closeAndOpen(ModalTypes.BonusPack);
  }

  if (userPermissions) {
    useZustandUserStore
      .getState()
      .setKycStatus(userPermissions.verificationStatus);
  }
};
const handleBonusPackInfo = (result: {
  isAvailableBonusPack: number;
  verificationStatus?: number;
}) => {
  const { isAvailableBonusPack, verificationStatus } = result;

  if (verificationStatus !== undefined) {
    useZustandUserStore.getState().setKycStatus(verificationStatus);
  }

  useZustandBonusPackStore
    .getState()
    .setIsAvailableStatus(Boolean(isAvailableBonusPack));
};

const updateBalance = (balance: BalanceInfo) => {
  const { showMoneyPopUpState, setShowPopUpState } =
    useZustandBalanceStore.getState();

  if (ShowMoneyPopUpState.WaitingBalance === showMoneyPopUpState) {
    setShowPopUpState(ShowMoneyPopUpState.Showed);

    if (!balance.mainBalance) {
      useZustandModalStore.getState().closeAndOpen(ModalTypes.MoneyOperations);
    }
  }

  useZustandBalanceStore.getState().setBalanceInfo(balance);
};

const registrationResponse = (result: HandleRegistrationResponse) => {
  const { status, login, password } = result;

  console.log('🚀 ~ registrationResponse ~ result:', result);

  // ErrorPromoCode = 65,

  const { registeredLogin, setRegisteredLogin } =
    useZustandRegistrationStore.getState();

  if (status) {
    const { addNotification } = useZustandNotificationStore.getState();

    // 64 promoCode enum
    // TODO: enums
    const text = status === 65 ? 'errors.invalidPromoCode' : 'errors.smth';

    addNotification({
      uid: `registration_failed+${new Date().getTime()}`,
      text: text,
      type: ENotificationType.Error,
    });

    if (registeredLogin) {
      setRegisteredLogin('');
    }

    return;
  }

  const data = {
    notClosed: true,
    login: registeredLogin || login,
    password,
  };

  useZustandModalStore.getState().closeAndOpen(ModalTypes.Credentials, data);
};

const handleUpdatePassword = (result: HandleRegistrationResponse) => {
  const { status } = result;

  if (status !== UpdatePasswordType.Success) {
    useZustandProfileStore.getState().setError('errors.passwordNotUpdated');
    useZustandProfileStore.getState().setStatus(UpdateStatus.Rejected);

    return;
  }

  useZustandProfileStore.getState().setStatus(UpdateStatus.Success);
  setToLocalStorage(TOKEN, result.password);
  useZustandUserStore.getState().updateUserInfo('token', result.password);
};

const handleRecoveryPassword = (result: HandleRegistrationResponse) => {
  const { status } = result;

  if (status === RecoveryPasswordStatus.UpdatePasswordAvailable) {
    useZustandModalStore.getState().closeAndOpen(ModalTypes.SetNewPassword, {
      notClosed: true,
    });

    return;
  }
  const { addNotification } = useZustandNotificationStore.getState();

  addNotification({
    uid: `recovery_failed+${new Date().getTime()}`,
    text: 'errors.recoveryPasswordFailed',
    type: ENotificationType.Error,
  });
};

const handleSetNewPassword = (result: HandleRegistrationResponse) => {
  const { status } = result;

  if (status === SetNewPasswordStatus.Success) {
    useZustandModalStore.getState().closeAndOpen(ModalTypes.SignIn);

    return;
  }
};

const assignUserInfoResponse = (result: HandleRegistrationResponse) => {
  const { status } = result;

  if (status === UpdateUserInfo.PhoneAssignedSuccess) {
    useZustandProfileStore.getState().setStatus(UpdateStatus.Success);

    return;
  }

  const { addNotification } = useZustandNotificationStore.getState();

  addNotification({
    uid: `registration_failed+${new Date().getTime()}`,
    text: 'errors.smth',
    type: ENotificationType.Error,
  });

  useZustandProfileStore.getState().setError('errors.tryAgain');
  useZustandProfileStore.getState().setStatus(UpdateStatus.Rejected);
};

const handleWalletResponse = (result: ParseResponseType) => {
  const { isError, isQR, url, iconType, text } = result;
  const requestId = result.requestId as ERequestIds;

  // TODO because of andrey response concerned with a lot of req
  const newText = text.includes('NEED_OPEN_APK = 7,')
    ? 'errors.tryAgain'
    : text;

  if (isError) {
    useZustandPaymentsStore.getState().setMessageInfo({
      iconType,
      text: newText,
      isQR,
      url,
    });

    return;
  }

  const a = document.createElement('a');

  const activePayment = getActivePaymentInfoSelector(
    useZustandPaymentsStore.getState()
  );

  const launchType = activePayment
    ? activePayment.launchType
    : EPaymentLaunch.Redirect;

  const isMobile = isMobileDeviceSelector(useZustandDeviceStore.getState());

  const isINR =
    requestId === ERequestIds.GoogleInrDeposit ||
    requestId === ERequestIds.DepositPayTm ||
    requestId === ERequestIds.DepositPhonePe;

  const isCustomRedirect = isINR && isMobile;

  if (
    EPaymentLaunch.Qr === launchType ||
    (isQR && !isCustomRedirect) ||
    activePayment?.id === PaymentChannelType.Rocket
  ) {
    return useZustandPaymentsStore.getState().setMessageInfo({
      iconType,
      text: newText,
      isQR,
      url,
    });
  }

  if (EPaymentLaunch.Redirect === launchType || isCustomRedirect) {
    a.setAttribute('href', url);
    a.click();

    return;
  }

  useZustandPaymentsStore.getState().setStep(Steps.RedirectInFrame, url);
};

const handleCriptoWalletResponse = (result: HandleDepositQrFormResponse) => {
  const { isError, qrInfo, tagText, responseText } = result;

  if (isError) {
    useZustandPaymentsStore.getState().setMessageInfo({
      iconType: EIconType.Error,
      text: responseText,
      isQR: false,
      url: '',
    });

    return;
  }

  useZustandPaymentsStore.getState().setDepositQrFormInfo({
    tagText: tagText,
    walletText: qrInfo,
  });
};

// BONUS PACK
export const adaptedBonus = (
  bonus: BonusPack,
  currencyId: number
): AdaptedBonusInfo | null => {
  const { game, pack_id, percent_or_freespins, settings, type, wager } = bonus;

  const bonusInfo = settings.find((s) => s.id_currency === currencyId);

  if (!bonusInfo) {
    return null;
  }

  return {
    gameId: game,
    packId: pack_id,
    bonusOffer: percent_or_freespins,
    bonusInfo,
    type,
    wager,
  };
};

export const handleTokenUpdate = (data: HandleTokenUpdate) => {
  const { at1 } = data;

  if (!at1) {
    removeFromLocalStorage(TOKEN);

    return;
  }
  setToLocalStorage(TOKEN, at1);

  useZustandUserStore.getState().setPermanentToken(at1);
};

export const handleLoyaltySuccess = (
  data: HandleCasinoLoyaltyFrameResponse
) => {
  useZustandFrameLoyaltyStore.getState().setLoyaltyLink(data.url);
};

export const handleBillingAddressResponse = (
  result: HandleBillingAddressResponse
) => {
  const { imgType, billingAccount, isError } = result;

  const { setBdtTwpStepInfo, setFormInfo } = useZustandFormsStore.getState();

  setBdtTwpStepInfo({
    imgType,
    billingAccount,
    isError,
  });

  setFormInfo(null);
  useZustandPaymentsStore.getState().setStep(Steps.TypingData);
};

export const handleNotification = (result: {
  code: ENotifications;
  data: { description?: string; code?: number };
}) => {
  try {
    const { code, data } = result;

    let type = ENotificationType.Default;
    let text = '';
    const uid = `${code}_${new Date().getTime()}`;

    switch (code) {
      case ENotifications.ReplacedBet:
        type = ENotificationType.Warning;
        text = 'notifications.betHasBeenReplaced';
        break;
      case ENotifications.LootBoxTakeMonthly:
        type = ENotificationType.Success;
        text = 'notifications.lootBoxRealCash';
        break;
      case ENotifications.BalanceIsNotAvailable:
        type = ENotificationType.Error;
        text = 'T.BalanceIsNotAvailable';
        break;
      case ENotifications.TicketFinished:
        type = ENotificationType.Success;
        text = 'notifications.settledTicket';
        break;
      case ENotifications.WheelIsNotAvailable:
        type = ENotificationType.Warning;
        text = 'notifications.wheelError';
        break;
      case ENotifications.SmsCodeSended:
        type = !result.data.code
          ? ENotificationType.Success
          : ENotificationType.Error;
        text = !result.data.code
          ? 'notifications.smsCodeSended'
          : 'errors.invalidDate';
        break;
      case ENotifications.OperationCanMayAFewMinutes:
        type = ENotificationType.Warning;
        text = 'notifications.operationCanMayAFewMinutes';
        break;
      case ENotifications.PromoCodeError:
        type = ENotificationType.Error;
        text = 'notifications.promoCodeError';
        break;
      case ENotifications.PromoCodeSuccess:
        useZustandModalStore
          .getState()
          .closeAndOpen(ModalTypes.PromoCodeActivate, data);
        break;
      case ENotifications.ReferralWithdrawError:
      case ENotifications.ReferralWithdrawSuccess:
        type =
          code === ENotifications.ReferralWithdrawSuccess
            ? ENotificationType.Success
            : ENotificationType.Error;

        useZustandModalStore
          .getState()
          .setModalInfo(
            code === ENotifications.ReferralWithdrawSuccess
              ? ModalTypes.ReferralSuccess
              : ModalTypes.ReferralFailure
          );
        text =
          code === ENotifications.ReferralWithdrawSuccess
            ? 'notifications.referralSuccess'
            : 'notifications.referralReject';

        // TODO: success - > open popUps
        break;

      default:
        break;
    }

    if (type !== ENotificationType.Default) {
      useZustandNotificationStore.getState().addNotification({
        type,
        text,
        uid,
      });
    }
  } catch (error) {
    console.info(error, 'error');
  }
};
