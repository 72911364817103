import { create } from 'zustand';
import { ERegistrationForm, ZustandRegistrationStore } from './types';
import {
  SIGN_UP_URL_COUNTRY_ID,
  SIGN_UP_URL_CURRENCY_NAME,
} from 'constants/ui';
import { getValueByIdFromUrl } from 'utils/urlParser';
import {
  GET_AVAILABLE_REGISTRATION_FORM,
  GET_INITIAL_REGISTRATION_FORM,
} from 'constants/marketingTags';

export const useZustandRegistrationStore = create<ZustandRegistrationStore>(
  (set, get) => ({
    availableCurrencies: [],
    availableRegistrationForm: GET_AVAILABLE_REGISTRATION_FORM,
    availableLandingRegistrationForm: [],

    registeredLogin: '',

    activeForm: GET_INITIAL_REGISTRATION_FORM,
    activeCurrencyId: null,
    activeCountryId: SIGN_UP_URL_COUNTRY_ID || null,

    setActiveCurrencyId: (activeCurrencyId) => {
      set({ activeCurrencyId });
    },
    setRegisteredLogin: (registeredLogin) => {
      set({ registeredLogin });
    },
    setActiveCountryId: (activeCountryId) => {
      set({ activeCountryId });
    },
    setAvailableRegistrationTypes: (keys) => {
      // TODO: marketing forms
      if (GET_AVAILABLE_REGISTRATION_FORM.length) {
        return;
      }
      const availableRegistrationForm: ERegistrationForm[] = [];

      keys.forEach((key: string) => {
        if (key in ERegistrationForm) {
          availableRegistrationForm.push(
            ERegistrationForm[key as keyof typeof ERegistrationForm]
          );
        }
      });
      set({ availableRegistrationForm });
    },
    setAvailableLandingRegistrationTypes: (keys) => {
      const availableLandingRegistrationForm: ERegistrationForm[] = [];

      keys.forEach((key: string) => {
        if (key in ERegistrationForm) {
          availableLandingRegistrationForm.push(
            ERegistrationForm[key as keyof typeof ERegistrationForm]
          );
        }
      });
      set({ availableLandingRegistrationForm });
    },
    setActiveRegistrationForm: (activeForm) => {
      set({ activeForm, registeredLogin: '' });
    },
    setCurrencies: (availableCurrencies) => {
      if (!availableCurrencies.length) {
        console.warn('PLACE PROVIDE REGISTRATION CURRENCIES');

        return;
      }

      const { activeCurrencyId } = get();

      let newActiveCurrencyId = null;

      if (!activeCurrencyId && SIGN_UP_URL_CURRENCY_NAME) {
        newActiveCurrencyId =
          availableCurrencies.find(
            (c) =>
              c.name.toLocaleLowerCase() ===
              SIGN_UP_URL_CURRENCY_NAME.toLocaleLowerCase()
          )?.id || null;
      }

      set({ availableCurrencies, activeCurrencyId: newActiveCurrencyId });
    },
  })
);
