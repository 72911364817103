import { Text, getCasinoCategoryIcon } from 'decryption_component-kit';
import { ReactComponent as ArrowSvg } from 'assets/base/arrow.svg';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './ProviderRowHeader.module.css';

type Props = {
  categoryName: string;
};

const CasinoHomeRowHeader = ({ categoryName }: Props) => {
  const setModal = useZustandModalStore(setModalInfoAction);

  const jumpTo = () => {
    setModal(ModalTypes.CasinoProviders);
  };

  const Icon = getCasinoCategoryIcon(100);

  return (
    <div className={styles.categoryHeaderWrapper}>
      <div className={styles.leftInfo}>
        {Icon && <Icon classes={styles.categoryIcon} />}

        <div className={styles.categoryName}>
          <Text idT={categoryName} />
        </div>
      </div>
      <div className={styles.showAllBtn} onClick={jumpTo}>
        <div>
          <Text idT="showMoreBtn" />
        </div>

        <ArrowSvg className={styles.arrowMore} />
      </div>
    </div>
  );
};

export default CasinoHomeRowHeader;
