import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useTranslate,
  CasinoCategoryButton,
  getCasinoCategoryIcon,
} from 'decryption_component-kit';
import cln from 'classnames';
import {
  useCasinoCategories,
  useWagerBonuses,
} from 'decryption_protocol_hooks';
import {
  CasinoCategory,
  ECasinoCategoryIds,
} from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';

import ArrowButton from 'components/ui/Buttons/ArrowButton';
import CasinoSearchInput from '../CasinoSearchInput';
import CasinoSubCategories from '../CasinoSubCategories';

import { ERouteNames } from 'Routes/types';

import { isDesktopDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import { setActiveProviderIdAction } from 'stores/casino/games/selectors';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getAdditionalCategoryInfoSelector } from 'stores/configuration/selectors';

import styles from './CasinoCategories.module.css';
import { getCasinoInHouseIcon } from 'utils/icons';

type Props = {
  type?: 'buttons';
  classes?: string;
  withoutSubCategory?: boolean;
  withoutSearch?: boolean;
  isProviderBtnActive?: boolean;
};

const CasinoCategories = (props: Props) => {
  const {
    type,
    classes,
    withoutSubCategory = false,
    withoutSearch = false,
    isProviderBtnActive = false,
  } = props;

  const { categoryId = '' } = useParams();

  const isDesktop = useZustandDeviceStore(isDesktopDeviceSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const additionalCategoryInfo = useZustandWebConfiguration(
    getAdditionalCategoryInfoSelector
  );

  const categories = useCasinoCategories({
    isMobile: !isDesktop,
    isConnectedApp,
  }) as CasinoCategory[];

  const [isAvailableLeftArrow, setLeftArrowState] = useState(false);

  const [isAvailableRightArrow, setRightArrowState] = useState(true);

  const container = useRef<HTMLDivElement | null>(null);

  const stickyWrapper = useRef<HTMLDivElement | null>(null);

  const { getText } = useTranslate('', '');

  const navigate = useNavigate();

  const hasAnyWagerBonuses = useWagerBonuses({ isConnectedApp });

  const setActiveProviderId = useZustandCasinoGamesStore(
    setActiveProviderIdAction
  );

  const handleSetActiveCategory = (id: number | null) => {
    setActiveProviderId(null);
    navigate(
      ERouteNames.CasinoCategories.replace(':categoryId', `${id}`).replaceAll(
        ':subCategoryId',
        'all'
      )
    );
  };

  const handleScroll = (value: number) => () => {
    if (!container.current) {
      return;
    }
    container.current?.scrollBy({
      behavior: 'smooth',
      left: value,
      top: 0,
    });

    const { offsetWidth, scrollWidth, scrollLeft } = container.current;

    if (scrollLeft) {
      setLeftArrowState(true);
    }

    if (!scrollLeft) {
      setLeftArrowState(false);
    }

    if (offsetWidth + scrollLeft >= scrollWidth) {
      setRightArrowState(false);
    }

    if (offsetWidth + scrollLeft < scrollWidth) {
      setRightArrowState(true);
    }
  };

  const jumpToProviders = () => {
    if (!additionalCategoryInfo) {
      return;
    }
    navigate(
      ERouteNames.CasinoProviders.replace(
        ':providerNames',
        additionalCategoryInfo.providerCategoryNames
      )
    );
  };

  return (
    <>
      <div
        className={cln(styles.wrapperContentCategories, classes)}
        ref={stickyWrapper}
      >
        <div
          className={cln(styles.wrapperScrollArrow, {
            [styles.fullWidth]: isDesktop && withoutSearch,
          })}
        >
          {isDesktop && (
            <div
              className={cln(styles.arrowButton, {
                [styles.disabled]: !isAvailableLeftArrow,
              })}
              onClick={handleScroll(-250)}
            >
              <ArrowButton classes={styles.left} />
            </div>
          )}
          <div
            className={cln(styles.wrapperCasinoCategories, styles.scroll)}
            ref={container}
          >
            {additionalCategoryInfo && (
              <CasinoCategoryButton
                id={additionalCategoryInfo.iconId}
                name={getText(additionalCategoryInfo.translateKey)}
                type={type}
                Icon={
                  additionalCategoryInfo.iconId === ECasinoCategoryIds.Turbo
                    ? getCasinoInHouseIcon()
                    : getCasinoCategoryIcon(additionalCategoryInfo.iconId)
                }
                isActive={isProviderBtnActive}
                handleClick={jumpToProviders}
              />
            )}

            {categories.map((category) => (
              <CasinoCategoryButton
                key={category.id}
                isWager={
                  !hasAnyWagerBonuses &&
                  category.id === ECasinoCategoryIds.Wager
                }
                Icon={
                  category.id === ECasinoCategoryIds.Turbo
                    ? getCasinoInHouseIcon()
                    : getCasinoCategoryIcon(category.id)
                }
                id={category.id}
                isActive={category.id === +categoryId}
                name={category.name}
                type={type}
                isInHouse={category.id === ECasinoCategoryIds.Turbo}
                handleClick={handleSetActiveCategory}
              />
            ))}
          </div>

          {isDesktop && (
            <div
              className={cln(styles.arrowButton, {
                [styles.disabled]: !isAvailableRightArrow,
              })}
              onClick={handleScroll(250)}
            >
              <ArrowButton classes={styles.right} />
            </div>
          )}
        </div>

        {!withoutSearch && isDesktop && (
          <div className={styles.searchWrapper}>
            <CasinoSearchInput />
          </div>
        )}
      </div>

      {!withoutSubCategory && <CasinoSubCategories />}
    </>
  );
};

export default CasinoCategories;
