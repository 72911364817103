import { useNavigate } from 'react-router';
import cln from 'classnames';
import { AdaptedCasinoTournament } from 'decryption_protocol/dist/lib/store/casino/entities/casinoTournaments/types';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';
import CountDownKit from 'components/ui/KitComponents/CountDownKit';

import { ReactComponent as PlaySvg } from 'assets/casino/play.svg';
import { ReactComponent as InfoSvg } from 'assets/base/info.svg';
import { Text } from 'decryption_component-kit';

import { APP_TYPE, IS_FSWIN } from 'constants/ui';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';

import { ERouteNames } from 'Routes/types';

import styles from './TournamentCard.module.css';

type Props = AdaptedCasinoTournament & {
  isFinished?: boolean;
  classes?: string;
};

const TournamentCard = (props: Props) => {
  const { amount, endDate, isFinished = false, classes } = props;

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const navigate = useNavigate();

  const handleOpenTournamentRules = () => {
    setModal(ModalTypes.TournamentRules);
  };

  const jumpToSlots = () => {
    navigate(
      ERouteNames.CasinoCategories.replace(
        ':categoryId',
        `${ECasinoCategoryIds.Slots}`
      ).replace(':subCategoryId', 'all')
    );
    setModal(ModalTypes.Unknown);
  };

  return (
    <div
      className={cln(styles.wrapperTournamentCard, classes)}
      style={{
        background: isFinished
          ? 'url(/icons-elon/tournaments/bg.png)'
          : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {!isFinished && (
        <img
          className={cln(styles.shortLogoIcon, {
            [styles.battleIconFSWin]: IS_FSWIN,
          })}
          src={`/icons-elon/tournaments/${APP_TYPE}/battle-logo.svg`}
        />
      )}

      {/* // <ShortLogo  />} */}

      <div className={styles.tournamentName}>
        <Text
          idT={isFinished ? 'tournaments.notActive' : 'tournaments.active'}
        />
      </div>

      <div className={styles.wrapperCountDown}>
        {!isFinished && (
          <CountDownKit
            endTimestamp={endDate}
            wrapperClasses={styles.wrapperCountDownClasses}
            timeClasses={styles.timeText}
          />
        )}
      </div>

      {!isFinished && (
        <div className={styles.winSum}>
          <Text idT="tournaments.prize" />
        </div>
      )}

      <div className={styles.rowResultAndButtons}>
        <div
          className={cln(styles.sum, {
            [styles.noPadding]: IS_FSWIN,
          })}
        >
          {isFinished && (
            <div className={styles.winSum}>
              <Text idT="tournaments.prize" />
            </div>
          )}
          {currencyName} {amount.toLocaleString('de-DE')}
        </div>

        {!isFinished && (
          <div className={styles.buttons}>
            <div className={styles.info} onClick={handleOpenTournamentRules}>
              <InfoSvg />
            </div>

            <div className={styles.play} onClick={jumpToSlots}>
              <PlaySvg />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TournamentCard;
