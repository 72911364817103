import { useParams } from 'react-router';
import { useCasinoGames } from 'decryption_protocol_hooks';

import CasinoGridGames from 'components/ui/CasinoGridGames';
import NotFoundData from 'components/ui/DataNotFound';

import { CASINO_LIMIT_GAMES } from 'constants/transport';
import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import {
  getGamesPageSelector,
  setGamesPageAction,
} from 'stores/casino/games/selectors';

import { useZustandCasinoSearch } from 'stores/casino/search';
import { getCasinoSearchValueSelector } from 'stores/casino/search/selectors';

import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './CasinoGridGames.module.css';
import { CasinoGamesResponse } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

const GridGames = ({ providerId = null }: { providerId?: number | null }) => {
  const { categoryId = 0, subCategoryId = 0 } = useParams();

  const page = useZustandCasinoGamesStore(getGamesPageSelector);

  const setPage = useZustandCasinoGamesStore(setGamesPageAction);

  const search = useZustandCasinoSearch(getCasinoSearchValueSelector);

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { games = [], isLastPage = true } = useCasinoGames({
    categoryId: +categoryId ? +categoryId : null,
    subCategoryId: +subCategoryId ? +subCategoryId : null,
    limit: CASINO_LIMIT_GAMES,
    providerId,
    page,
    search,
    isMobile,
    isConnectedApp,
  }) as CasinoGamesResponse;

  const handleShowMore = () => {
    setPage(page + 1);
  };

  return (
    <div className={styles.wrapperCasinoGridGames}>
      <CasinoGridGames
        games={games}
        isLastPage={isLastPage}
        handleClickShowMore={handleShowMore}
      />

      {!games.length && <NotFoundData />}
    </div>
  );
};

export default GridGames;
