import cln from 'classnames';
import { useEffect, useState } from 'react';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';

import { ReactComponent as CopySvg } from 'assets/base/copy.svg';
import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';
import { Text } from 'decryption_component-kit';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';

import { useZustandFormsStore } from 'stores/money/forms/store';
import {
  getBdtStepTwoInfoSelector,
  setFormsInfoAction,
} from 'stores/money/forms/selectors';

import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { Steps } from 'stores/money/payments/types';

import { composeValidations, isRequired, upperCase } from 'utils/validation';
import { copyToClipboard } from 'utils/shared';

import styles from './BKash.module.css';
import depositStyles from '../Deposit.module.css';

type Props = {
  limit: { min: number; max: number };
};

const BKash = ({ limit }: Props) => {
  const bdtTwoStepInfo = useZustandFormsStore(getBdtStepTwoInfoSelector);

  const [transactionId, setTransactionId] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState<string>('');

  const validationDocumentId = (value: string) => {
    let errorText =
      composeValidations([
        isRequired({ value, errorText: 'errors.required' }),
      ]) || '';

    if (!errorText && value.length !== 10) {
      errorText = 'errors.bkashTransactionIdLength';
    }

    setTransactionId({
      value,
      errorText,
    });
  };

  useEffect(() => {
    if (amount && currencyId) {
      setStep(Steps.BdtStepTwo);
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          currencyId,
          channelId: PaymentChannelType.BKash,
          requestId: ERequestIds.GetBillingAddress,
        },
      });
      //
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId]);

  useEffect(() => {
    if (!amount || !currencyId || !bdtTwoStepInfo) {
      return;
    }

    if (!transactionId.errorText && transactionId.value) {
      setFormInfo({
        isDeposit: true,
        form: {
          transaction: transactionId.value,
          amount,
          channelId: PaymentChannelType.BKash,
          currencyId,
          requestId: ERequestIds.DepositBKash,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [transactionId, currencyId, amount, bdtTwoStepInfo]);

  return (
    <div>
      <AmountInput
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.amount"
        size="full"
        onChange={setAmount}
        min={limit.min}
        max={limit.max}
        disabled={Boolean(bdtTwoStepInfo)}
        classes={depositStyles.amountInputMargin}
        wrapperClasses={depositStyles.marginInputBottom}
      />

      {bdtTwoStepInfo && (
        <>
          <Input
            defaultValue={bdtTwoStepInfo.billingAccount}
            inputStyleType="border-colorful"
            inputLabelType="labelUp"
            placeholder="walletPopUp.forms.billingAccount"
            size="full"
            disabled
            onChange={console.info}
            classes={cln(
              styles.billingAccountInput,
              depositStyles.marginInputBottom
            )}
          >
            <CopySvg
              className={styles.copyIcon}
              onClick={copyToClipboard(bdtTwoStepInfo.billingAccount)}
            />
          </Input>

          <div className={cln(depositStyles.marginInputBottom, styles.bdtInfo)}>
            <Text idT="walletPopUp.forms.bdtInfo" />
          </div>

          <Input
            defaultValue=""
            placeholder="walletPopUp.forms.transactionId"
            size="full"
            inputStyleType="border-colorful"
            inputLabelType="labelUp"
            errorText={transactionId.errorText}
            onChange={validationDocumentId}
            patternFn={upperCase}
            classes={depositStyles.marginInputBottom}
          />
        </>
      )}
    </div>
  );
};

export default BKash;
