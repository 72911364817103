import { useMemo } from 'react';
import cln from 'classnames';
import { Logo } from 'decryption_component-kit';
import { useAuth, useInstaNewsExperimental } from 'decryption_protocol_hooks';

import { useLocation, useNavigate } from 'react-router';

import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';

import { ERouteNames } from 'Routes/types';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { isFrameBettingSelector } from 'stores/configuration/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandInstaNewsStore } from 'stores/instaNews/store';
import { getLastHistoryStoriesSelector } from 'stores/instaNews/selectors';
import { APP_TYPE, IS_DEVELOPMENT_MODE } from 'constants/ui';
import { EAppType } from 'decryption_component-kit/dist/lib/types/ui';

import styles from './ComponentKitLogo.module.css';

const ComponentKitLogo = ({
  classes,
  customIsMobile,
}: {
  classes?: string;
  customIsMobile?: boolean;
}) => {
  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const isFrame = useZustandWebConfiguration(isFrameBettingSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const histories = useInstaNewsExperimental({ isConnectedApp });

  const lastId = useZustandInstaNewsStore(getLastHistoryStoriesSelector);

  const isAuth = useAuth({ isConnectedApp });

  const existNewHistory = useMemo(() => {
    const post = histories[histories.length - 1];

    if (!post) {
      return false;
    }

    return post.id !== lastId;
  }, [histories, lastId]);

  const navigate = useNavigate();

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const { pathname } = useLocation();

  const isBetting = pathname.includes('betting');

  const setModal = useZustandModalStore(setModalInfoAction);

  const returnToMain = () => {
    if (existNewHistory && IS_DEVELOPMENT_MODE) {
      setModal(ModalTypes.InstaShorts);

      return;
    }
    const path =
      isBetting && !isFrame ? ERouteNames.BettingHome : ERouteNames.CasinoHome;

    navigate(path);
  };

  const isMobileProps =
    customIsMobile !== undefined ? customIsMobile : isMobile;

  return (
    <Logo
      appType={APP_TYPE}
      isMobile={isAuth ? isMobileProps : false}
      handleLogoClick={returnToMain}
      isFullView={isFullView}
      classes={cln(classes, {
        [styles.mobileElonSize]:
          (isMobile && APP_TYPE !== EAppType.JabiBet) || !isAuth,
      })}
    />
  );
};

export default ComponentKitLogo;
