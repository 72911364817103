import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AdaptedGame } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';
import cln from 'classnames';

import HOCImageWithDefault from 'components/HOCs/HOCImageWithDefault';
import ProviderName from '../CasinoProviderName';
import DefaultGameImg from 'components/ui/DefaultGameImg';

import { ERouteNames } from 'Routes/types';

import { IS_DEVELOPMENT_MODE, IS_VERTICAL } from 'constants/ui';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './CasinoGame.module.css';

const CasinoGame = (props: AdaptedGame & { classes?: string }) => {
  const {
    providerName,
    imageUrl,
    id,
    name,
    providerId,
    specialLabel,
    classes,
  } = props;

  const navigate = useNavigate();

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const jumpTo = () => {
    setModal(ModalTypes.Unknown);
    navigate(ERouteNames.CasinoGame.replace(':gameId', `${id}`));
  };

  return (
    <div
      className={cln(
        styles.wrapperCasinoGame,
        {
          [styles.verticalSize]: IS_VERTICAL,
        },
        classes
      )}
      onClick={jumpTo}
    >
      <HOCImageWithDefault
        src={IS_DEVELOPMENT_MODE ? `https://elondom.com${imageUrl}` : imageUrl}
        classes={styles.gameImg}
      >
        <DefaultGameImg classes={styles.gameImg} gameName={name} />
      </HOCImageWithDefault>

      <ProviderName
        classes={styles.providerName}
        providerName={providerName}
        providerId={providerId}
        specialLabel={specialLabel}
      />
    </div>
  );
};

export default React.memo(CasinoGame);
