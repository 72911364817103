import cln from 'classnames';

import { ReactComponent as ArrowBackSvg } from 'assets/base/arrow.svg';

import styles from './HeaderSport.module.css';

type Props = {
  classes?: string;
  children: React.ReactElement;
  goBack: () => void;
};

const BackHeader = (props: Props) => {
  const { children, classes, goBack } = props;

  return (
    <div className={cln(styles.wrapperHeaderSport, classes)} onClick={goBack}>
      <ArrowBackSvg className={styles.arrowBack} />

      {children}
    </div>
  );
};

export default BackHeader;
