import cln from 'classnames';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';

import { ReactComponent as ArrowSvg } from 'assets/base/arrow.svg';
import { Text, getCasinoCategoryIcon } from 'decryption_component-kit';

import styles from './RowHeader.module.css';
import { getCasinoInHouseIcon } from 'utils/icons';

type Props = {
  iconId: number;
  categoryName: string;
  jumpTo?: () => void;
  classes?: string;
};

const CasinoHomeRowHeader = (props: Props) => {
  const { iconId, categoryName, jumpTo, classes } = props;

  const Icon =
    iconId === ECasinoCategoryIds.Turbo
      ? getCasinoInHouseIcon()
      : getCasinoCategoryIcon(iconId);

  return (
    <div className={cln(styles.categoryHeaderWrapper, classes)}>
      <div
        className={cln(styles.leftInfo, {
          [styles.inHouse]: iconId === ECasinoCategoryIds.Turbo,
        })}
      >
        {Icon && <Icon classes={styles.categoryIcon} />}

        <div className={styles.categoryName}>
          <Text idT={categoryName} />
        </div>
      </div>

      {jumpTo && (
        <div className={styles.showAllBtn} onClick={jumpTo}>
          <div>
            <Text idT="showMoreBtn" />

            {/* <ArrowSvg className={styles.arrowShowMore} /> */}
          </div>

          <ArrowSvg className={styles.arrowMore} />
        </div>
      )}
    </div>
  );
};

export default CasinoHomeRowHeader;
