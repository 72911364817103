import cln from 'classnames';

import { ReactComponent as TwitterSvg } from 'assets/socials/twitter.svg';
import { ReactComponent as TelegramSvg } from 'assets/socials/telegram.svg';
import { ReactComponent as FacebookSvg } from 'assets/socials/facebook.svg';
import { ReactComponent as InstagramSvg } from 'assets/socials/instagram.svg';

import { getSocialInfoSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';

import styles from './SocialButtons.module.css';

const SocialButtons = () => {
  const socialButtons = useZustandWebConfiguration(getSocialInfoSelector);

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const handleOpenNewTab = (link: string) => () => {
    try {
      window.open(link, '_blank');
    } catch (error) {
      console.error('🚀 ~ link error:', error);
    }
  };

  return (
    <div
      className={cln(styles.socialButtonsWrapper, {
        [styles.shortView]: !isFullView,
      })}
    >
      {socialButtons?.twitter && (
        <div
          className={styles.socialButton}
          onClick={handleOpenNewTab(socialButtons.twitter)}
        >
          <TwitterSvg />
        </div>
      )}
      {socialButtons?.instagram && (
        <div
          className={styles.socialButton}
          onClick={handleOpenNewTab(socialButtons.instagram)}
        >
          <InstagramSvg />
        </div>
      )}
      {socialButtons?.facebook && (
        <div
          className={styles.socialButton}
          onClick={handleOpenNewTab(socialButtons?.facebook)}
        >
          <FacebookSvg />
        </div>
      )}
      {socialButtons?.telegram && (
        <div
          className={styles.socialButton}
          onClick={handleOpenNewTab(socialButtons?.telegram)}
        >
          <TelegramSvg />
        </div>
      )}
    </div>
  );
};

export default SocialButtons;
