import React, { useEffect } from 'react';
import { useInitTransport } from 'decryption_protocol_hooks';
import { ESocketCallbackStep } from 'decryption_protocol/dist/lib/socket/types';

import MainLoader from 'components/MainLoader';
import HOCAuthorization from '../HOCAuthorization';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getSocketTransportInfoSelector } from 'stores/configuration/selectors';
import {
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';

import { SETTINGS } from './constants';

import { decodeResponse } from 'services/adapters';

import {
  addRequestKeyForLoaderAction,
  resetRequestingLoadersSelector,
} from 'stores/request/selector';
import { useZustandRequestStore } from 'stores/request/store';
import { useZustandConnectionStore } from 'stores/connection/store';
import { setConnectionStatusAction } from 'stores/connection/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { setUserInfoAction } from 'stores/user/personal/selectors';
import { useZustandMainLoaderStore } from 'stores/mainLoader/store';
import { setMainLoaderPercentAction } from 'stores/mainLoader/selectors';

type Props = {
  children: React.ReactElement;
};

export const HOCSocket = ({ children }: Props) => {
  const transportInfo = useZustandWebConfiguration(
    getSocketTransportInfoSelector
  );

  const languageName = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const addKey = useZustandRequestStore(addRequestKeyForLoaderAction);

  const setStatus = useZustandConnectionStore(setConnectionStatusAction);

  const setUserInfo = useZustandUserStore(setUserInfoAction);

  const setPercent = useZustandMainLoaderStore(setMainLoaderPercentAction);

  const resetAllLoaders = useZustandRequestStore(
    resetRequestingLoadersSelector
  );

  const status = useInitTransport({
    transportInfo,
    languageName,
    initialSettings: SETTINGS,
    responseCallback: decodeResponse,
    callbackRequestKey: addKey,
  });

  useEffect(() => {
    if (status === ESocketCallbackStep.Connected) {
      setPercent(100);
    }
    setStatus(status);

    if (
      status === ESocketCallbackStep.Close ||
      status === ESocketCallbackStep.Error
    ) {
      if (!transportInfo) {
        console.warn('TRANSPORT INFO NOT FOUND');

        return;
      }

      setUserInfo(null);

      resetAllLoaders();
    }
  }, [status]);

  return (
    <>
      <MainLoader />

      <HOCAuthorization>{children}</HOCAuthorization>
    </>
  );
};
