import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import React, { useState } from 'react';
import { DefaultInputType, InputBaseProps } from '../Base/types';
import Input from '../../KitComponents/Inputs/ComponentKitBaseInput';
import { DEFAULT_INPUT_VALUE } from '../Base/constant';
import { VALIDATION_NEW_FLOW_RULES } from 'decryption_protocol/dist/lib/store/user/constants';

type Props = InputBaseProps & {
  channelId:
    | PaymentChannelType.BTC
    | PaymentChannelType.LTC
    | PaymentChannelType.BCH
    | PaymentChannelType.ADA
    | PaymentChannelType.ETH
    | PaymentChannelType.DOGE
    | PaymentChannelType.XRP
    | PaymentChannelType.USDTE
    | PaymentChannelType.USDTT
    | PaymentChannelType.BNB
    | PaymentChannelType.BNBBSC
    | PaymentChannelType.BUSD
    | PaymentChannelType.USDC
    | PaymentChannelType.TRX
    | PaymentChannelType.DAI;
};

const CoinPayInputWallet = (props: Props) => {
  const { channelId, onChange, ...inputProps } = props;

  const [value, setValue] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const handleValidate = (v: string) => {
    const regExs = VALIDATION_NEW_FLOW_RULES[channelId];

    const isValid = regExs.find((reg) => {
      const regEx = new RegExp(reg);

      return regEx.test(v);
    });

    setValue({
      value: v,
      errorText: !isValid ? 'errors.invalidValue' : '',
    });

    if (isValid) {
      onChange(v);
    } else {
      onChange('');
    }
  };

  return (
    <Input
      {...inputProps}
      errorText={value.errorText}
      onChange={handleValidate}
    />
  );
};

export default CoinPayInputWallet;
