import { useGetProviderGames } from 'decryption_protocol_hooks';
import { CasinoGamesResponse } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import { ReactComponent as RecommendedSvg } from 'assets/casino/recommended.svg';
import { Text } from 'decryption_component-kit';
import CasinoGridGames from 'components/ui/CasinoGridGames';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';

import styles from './CasinoRecommendedGames.module.css';

type Props = {
  providerId: number;
  excludeGameId?: number;
};

const RecommendedGames = ({ providerId, excludeGameId }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const response = useGetProviderGames({
    isConnectedApp,
    isMobile,
    id: providerId,
    excludeGameId,
    page: 1,
    limit: 6,
  }) as CasinoGamesResponse;

  const { games = [] } = response;

  if (!games.length) {
    return null;
  }

  return (
    <div>
      <div className={styles.recommendedTitle}>
        <RecommendedSvg className={styles.recommendedGamesSvg} />

        <div className={styles.title}>
          <Text idT="recommendedGames" />
        </div>
      </div>

      {!!games.length && (
        <CasinoGridGames
          games={games}
          classes={styles.editWidthSize}
          handleClickShowMore={console.info}
          // isLastPage={gameInfo.isLastPage}
          isLastPage
        />
      )}
    </div>
  );
};

export default RecommendedGames;
