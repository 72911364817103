import { useMemo, useState } from 'react';

import { ReactComponent as InfoSvg } from 'assets/base/info.svg';
import { ReactComponent as ErrorSvg } from 'assets/notifications/error.svg';

import styles from './Tooltip.module.css';
import { HOCOutsideClick } from 'decryption_component-kit';

type Props = {
  isError: boolean;
  src: string;
};

const InputTooltip = ({
  isError,
  src = '/icons-elon/payments/banks/turkish/akbank.svg',
}: Props) => {
  const [tooltipState, setTooltipState] = useState(false);

  const toggleShowState = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setTooltipState(!tooltipState);
  };

  return (
    <div className={styles.inputTooltip}>
      {/* {isError && <ErrorSvg />} */}

      <InfoSvg className={styles.grayColor} onClick={toggleShowState} />

      {tooltipState && (
        <HOCOutsideClick
          classes={styles.tooltipWrapper}
          handleFunc={toggleShowState}
        >
          <img src={src} className={styles.bankLogo} />

          <div className={styles.example}>TR46 0004 60.. .... .... .... 58</div>
        </HOCOutsideClick>
      )}
    </div>
  );
};

export default InputTooltip;
