import cln from 'classnames';

import { ReactComponent as PlaySvg } from 'assets/casino/play.svg';

import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import CircularLoader from 'components/ui/Loaders/Circular';

import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { useZustandUserStore } from 'stores/user/personal/store';
import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './PlayButton.module.css';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { getActiveBalanceInfoSelector } from 'stores/user/balance/selectors';

type Props = {
  handleClick: () => void;
  classes?: string;
};

const PlayButton = ({ handleClick, classes }: Props) => {
  const isAuth = useZustandUserStore(isAuthorizedUserSelector);
  const setModal = useZustandModalStore(setModalInfoAction);

  const balanceInfo = useZustandBalanceStore(getActiveBalanceInfoSelector);

  const play = () => {
    if (!isAuth) {
      setModal(ModalTypes.SignIn);

      return;
    }

    if (balanceInfo.balance <= 0) {
      setModal(ModalTypes.MoneyOperations);

      return;
    }
    handleClick();
  };

  return (
    <div className={cln(styles.wrapperPlayButton, classes)} onClick={play}>
      <HOCRequestLoader
        requestId={ERequestIds.InitCasinoGame}
        command="\\17x"
        customLoader={<CircularLoader />}
      >
        <PlaySvg />
      </HOCRequestLoader>
    </div>
  );
};

export default PlayButton;
