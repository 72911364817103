import { DrawerButton, useTranslate } from 'decryption_component-kit';

import { ReactComponent as ProviderSvg } from 'assets/base/providers-blocks.svg';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';

import { ModalTypes } from 'stores/modal/types';

import styles from './CasinoProviderButtons.module.css';

const CasinoProviderButtons = () => {
  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const { getText } = useTranslate('', '');

  const toggleState = () => {
    setModal(ModalTypes.CasinoProviders);
  };

  return (
    <DrawerButton
      size="full"
      text={getText('drawers.casino.providerBtn')}
      handleClick={toggleState}
      startIcon={<ProviderSvg className={styles.providersSvg} />}
      classes={styles.marginBottom}
      isFullView={isFullView}
    />
  );
};

export default CasinoProviderButtons;
