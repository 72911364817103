import React, { useState } from 'react';
import { Calendar } from 'react-calendar';
import cln from 'classnames';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import ModalTitle from 'components/ui/ModalTitle';

import { ReactComponent as CalendarSvg } from 'assets/base/calendar.svg';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import { useZustandCalendarFilterStore } from 'stores/filters/calendar/store';
import {
  getSelectedRangeSelector,
  setTimeRangeAction,
} from 'stores/filters/calendar/selectors';
import {
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';

import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import styles from './Calendar.module.css';

const CalendarModal = () => {
  const range = useZustandCalendarFilterStore(getSelectedRangeSelector);

  const setRange = useZustandCalendarFilterStore(setTimeRangeAction);

  const [currentRange, setNewCurrentRange] = useState(range);

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const activeLang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const closeModal = () => {
    setModal(ModalTypes.Unknown);
  };

  const handleSetNewValue = (range: [Date, Date]) => {
    setNewCurrentRange([range[0].getTime(), range[1].getTime()]);
  };

  const handleSaveNewRange = () => {
    setRange(currentRange);
    closeModal();
  };

  return (
    <div className={styles.wrapperCalendarModal}>
      <ModalTitle name="calendar" startIcon={<CalendarSvg />} />

      <Calendar
        // @ts-ignore
        onChange={handleSetNewValue}
        defaultValue={[new Date(currentRange[0]), new Date(currentRange[1])]}
        className={cln('calendarLib', styles.calendarMB)}
        selectRange
        locale={activeLang}
      />

      <ComponentKitBaseButton
        text="accept"
        handleClick={handleSaveNewRange}
        size="--button-full"
        buttonHeight="--button-height-l"
        color="primary"
        centreText
      />
    </div>
  );
};

export default CalendarModal;
