import { COPY_TO_CLIPBOARD } from 'constants/transport';
import { useZustandNotificationStore } from 'stores/notification/store';
import { ENotificationType } from 'stores/notification/types';

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getUid = () => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;

  while (counter < 5) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
};
export const copyToClipboard = (text: string) => async () => {
  try {
    await navigator.clipboard.writeText(text);

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: COPY_TO_CLIPBOARD,
        payload: {
          text,
        },
      })
    );

    useZustandNotificationStore.getState().addNotification({
      uid: `copy_failure+${new Date().getTime()}`,
      text: 'copied',
      type: ENotificationType.Success,
    });
  } catch (err) {
    console.error('🚀 FAIL TO COPY #1:', err);

    if (!window.ReactNativeWebView) {
      useZustandNotificationStore.getState().addNotification({
        uid: `copy_failure+${new Date().getTime()}`,
        text: 'errors.copyFailure',
        type: ENotificationType.Error,
      });
    }

    try {
      const textarea = document.createElement('textarea');

      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        const successful = document.execCommand('copy');

        if (successful) {
          console.log('Text copied');
        } else {
          console.error('Failed to copy');
        }
      } catch (err) {
        console.error('Failed to copy', err);
      }

      document.body.removeChild(textarea);
    } catch (error) {
      console.error('Failed to copy #2', error);
    }
  }
};

export const shuffle = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const getProviderName = (providerName: string) =>
  // eslint-disable-next-line quotes
  providerName.toLowerCase().replaceAll(' ', '').replaceAll("'", '_');

export const getRandomArbitrary = (min: number, max: number) => {
  const base = Math.random() * (max - min);

  return base + min;
};
