import cln from 'classnames';
import {
  ChannelType,
  CommonOperationProperties,
  MixerTransactionAdditionalInfo,
} from 'decryption_protocol/dist/lib/store/operations/types';

import BonusCardLayoutComponentKit from 'components/ui/KitComponents/BonusCardLayoutComponentKit';

import { APP_TYPE, IS_FSWIN } from 'constants/ui';

import styles from './BattleCard.module.css';

type Props = {
  info: MixerTransactionAdditionalInfo;
  operation: CommonOperationProperties;
  id: number;
};

const BattleCard = (props: Props) => {
  const { info, id, operation } = props;

  const { resultValue } = info;

  return (
    <BonusCardLayoutComponentKit
      title={
        operation.channelType === ChannelType.TournamentPayoutBonus
          ? 'newBonuses.cards.battlesBonus'
          : 'newBonuses.cards.battlesBonus'
      }
      description="newBonuses.cards.battleDescription"
      id={id}
      value={`${resultValue}`}
    >
      <img
        src={`/icons-elon/bonuses/cards/${APP_TYPE}/battle.png`}
        className={cln(styles.wrapperMonthlyCard, {
          [styles.imgHeight]: IS_FSWIN,
        })}
      />
    </BonusCardLayoutComponentKit>
  );
};

export default BattleCard;
