import { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { useTransportControllers } from 'decryption_protocol_hooks';

import { ReactComponent as WarningSvg } from 'assets/base/info.svg';
import { ReactComponent as CopySvg } from 'assets/base/copy.svg';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import Input from 'components/ui/Input/Base';
import { Text } from 'decryption_component-kit';
import SkeletLoader from 'components/ui/Loaders/SkeletLoader';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { getDepositQrFormInfoSelector } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { copyToClipboard } from 'utils/shared';

import styles from './QrForm.module.css';

type Props = {
  activeChannelId: PaymentChannelType;
  min: number;
};

const QrForm = ({ activeChannelId, min }: Props) => {
  const isAvailable = useZustandConnectionStore(isAvailableTransportSelector);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const info = useZustandPaymentsStore(getDepositQrFormInfoSelector);
  const { sendCommand } = useTransportControllers();

  useEffect(() => {
    if (!isAvailable || !currencyId) {
      return;
    }
    sendCommand(
      '49x',
      {
        bonusId: 0,
        currencyId,
        channelId: activeChannelId,
        amount: 0,
        requestId: ERequestIds.DepositQrForm,
      },
      true
    );
  }, [isAvailable]);

  return (
    <HOCRequestLoader
      command="49x"
      requestId={ERequestIds.DepositQrForm}
      customLoader={<SkeletLoader />}
    >
      <div className={styles.wrapperDepositQrForm}>
        <div className={styles.depositQrTitle}>
          <Text idT="walletPopUp.depositQrForm.title" />
        </div>

        <QRCode
          value={info?.walletText || ''}
          size={186}
          className={styles.qrWrapper}
        />

        {info && (
          <>
            <Input
              placeholder="walletPopUp.depositQrForm.walletInfo"
              size="full"
              onChange={console.info}
              inputStyleType="border-colorful"
              inputLabelType="labelUp"
              disabled
              defaultValue={info.walletText}
              classes={styles.inputInfo}
            >
              <CopySvg onClick={copyToClipboard(info.walletText)} />
            </Input>
            {info.tagText && (
              <Input
                placeholder="walletPopUp.depositQrForm.tagInfo"
                size="full"
                inputStyleType="border-colorful"
                inputLabelType="labelUp"
                onChange={console.info}
                disabled
                defaultValue={info.tagText}
                classes={styles.inputInfo}
              >
                <CopySvg onClick={copyToClipboard(info.tagText)} />
              </Input>
            )}
          </>
        )}

        <div className={styles.minInfo}>
          <WarningSvg />

          <div className={styles.minText}>
            <Text
              idT="walletPopUp.depositQrForm.minAmountForDeposit"
              specValue={`${min} ${PaymentChannelType[activeChannelId]}`}
            />
          </div>
        </div>
      </div>
    </HOCRequestLoader>
  );
};

export default QrForm;
