import { useNavigate } from 'react-router';
import { ProviderNameButton } from 'decryption_component-kit';
import { useWagerBonuses } from 'decryption_protocol_hooks';
import { EAdaptedGameSpecialLabel } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import { ReactComponent as TournamentWager } from 'assets/casino/categories/tournamentWager.svg';
import { ReactComponent as WagerIcon } from 'assets/casino/categories/wager.svg';

import { ERouteNames } from 'Routes/types';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { useMemo } from 'react';
import { IS_VERTICAL } from 'constants/ui';

type Props = {
  providerName: string;
  classes?: string;
  providerId: number;
  justIcon?: boolean;
  specialLabel?: EAdaptedGameSpecialLabel;
};

const ProviderNameKit = (props: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );
  const hasAnyWagerBonuses = useWagerBonuses({ isConnectedApp });

  const {
    providerId,
    providerName,
    classes,
    specialLabel,
    justIcon = IS_VERTICAL,
  } = props;

  const navigate = useNavigate();

  const jumpTo = () => {
    navigate(
      ERouteNames.CasinoProviderGames.replaceAll(':id', `${providerId}`)
    );
  };

  const isSpecialIcon =
    hasAnyWagerBonuses && specialLabel !== EAdaptedGameSpecialLabel.Unknown;

  const Icon = useMemo(() => {
    if (!isSpecialIcon || !specialLabel) {
      return null;
    }

    const WAGER_INFO = [
      EAdaptedGameSpecialLabel.MultiWagering,
      EAdaptedGameSpecialLabel.WagerBonus,
    ];

    if (WAGER_INFO.includes(specialLabel)) {
      return WagerIcon;
    }

    return TournamentWager;
  }, [isSpecialIcon, specialLabel]);

  return (
    <ProviderNameButton
      classes={classes}
      jumpTo={jumpTo}
      SpecIcon={Icon}
      providerName={justIcon ? '' : providerName} //
    />
  );
};

export default ProviderNameKit;
