import cln from 'classnames';
import {
  EOperationStatus,
  MixerTransactionAdditionalInfo,
} from 'decryption_protocol/dist/lib/store/operations/types';

import BonusCardLayoutComponentKit from 'components/ui/KitComponents/BonusCardLayoutComponentKit';

import { APP_TYPE, IS_FSWIN } from 'constants/ui';

import styles from './SupportCard.module.css';

type Props = {
  info: MixerTransactionAdditionalInfo;
  id: number;
};

const SupportCard = ({ info, id }: Props) => {
  const { status, resultValue, endTimestamp } = info;

  if (status !== EOperationStatus.Created) {
    return null;
  }

  return (
    <BonusCardLayoutComponentKit
      title="newBonuses.cards.egt"
      description="newBonuses.cards.egtDescription"
      id={id}
      value={`${resultValue}`}
      endTimestamp={endTimestamp}
    >
      <img
        src={`/icons-elon/bonuses/cards/${APP_TYPE}/support.png`}
        className={cln(styles.wrapperSupportCard, {
          [styles.imgHeight]: IS_FSWIN,
        })}
      />
    </BonusCardLayoutComponentKit>
  );
};

export default SupportCard;
