import { create } from 'zustand';
import { EKYCStatus, ZustandUserStore } from './types';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from 'utils/localstorage';
import { TOKEN, TOKEN_OLD_SUPPORT } from 'constants/localstoreKeys';
import { getCookie } from 'utils/cookie';
import { useTransportControllers } from 'decryption_protocol_hooks';
import { ENCRYPT_DATA } from 'constants/marketingTags';
import { decrypt } from 'stores/utils/crypto';
import { getInitialToken } from './utils';

console.info(getFromLocalStorage(TOKEN), 'getFromLocalStorage(TOKEN)');

export const useZustandUserStore = create<ZustandUserStore>((set, get) => ({
  userInfo: null,
  pushToken: null,
  kycStatus: EKYCStatus.Unknown,
  permanentToken: getInitialToken(),

  setPushToken: (pushToken) => {
    set({ pushToken });
  },

  setPermanentToken: (permanentToken: string) => {
    set({ permanentToken });
  },
  setKycStatus: (kycStatus) => {
    set({ kycStatus });
  },

  updateUserInfo: (key, value) => {
    const { userInfo } = get();

    if (!userInfo) {
      return;
    }

    set({
      userInfo: {
        ...userInfo,
        [key]: value,
      },
    });
  },

  setUserInfo: (userInfo) => {
    set({ userInfo });
  },
  logout: () => {
    set({ userInfo: null, permanentToken: '', kycStatus: EKYCStatus.Unknown });

    removeFromLocalStorage(TOKEN);
    removeFromLocalStorage(TOKEN_OLD_SUPPORT);

    useTransportControllers().endpoints?.logout();
  },
}));
