import {
  DrawerButton,
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';

import { ReactComponent as DocSvg } from 'assets/base/doc.svg';

import { getPartnersLinkSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';

import styles from './DrawerDocs.module.css';

const DrawerDocs = () => {
  const activeLangName = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const partnerLink = useZustandWebConfiguration(getPartnersLinkSelector);

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const { getText } = useTranslate('', '');

  const handleOpenLink =
    (path: string, isDoc = true) =>
    () => {
      if (!isDoc) {
        window.open(path, '_blank', 'fullscreen=yes');

        return;
      }

      if (!activeLangName) {
        return;
      }
      const validPathname = path.replace('[lang]', activeLangName);

      window.open(
        `${window.location.origin}${validPathname}`,
        '_blank',
        'fullscreen=yes'
      );
    };

  if (!partnerLink) {
    return null;
  }

  return (
    <DrawerButton
      size="full"
      text={getText('drawers.casino.partners')}
      startIcon={<DocSvg className={styles.green} />}
      handleClick={handleOpenLink(partnerLink, false)}
      classes={styles.marginBottom}
      isFullView={isFullView}
    />
  );
};

export default DrawerDocs;
