import React, { useEffect, useState } from 'react';
import { useBetSlipMaxLimit } from 'decryption_protocol_hooks';

import { ECustomBetUid } from 'decryption_protocol/dist/lib/store/betting/entities/betslip/types';

import { ReactComponent as TrashSvg } from 'assets/betting/trash.svg';
import BaseInputUi from '../Base/BaseInputUi';
import MaxBet from 'components/Betting/BetSlipContent/components/Templates/MaxBet';

import { DefaultInputType } from '../Base/types';
import { DEFAULT_INPUT_VALUE } from '../Base/constant';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './BetSlipAmount.module.css';

type Props = {
  isError: boolean;
  setStake: ({ betUid, stake }: { betUid: string; stake: string }) => void;
  betUid: string;
  isPrematch: boolean;
  eventUid: string;
  lineUid: string;
  outcomeId: number;
  defaultStake: string;
};

const BetSlipAmount = (props: Props) => {
  const {
    setStake,
    isError,
    betUid,
    isPrematch,
    eventUid,
    lineUid,
    outcomeId,
    defaultStake,
  } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const [uiStake, setUiStake] = useState<DefaultInputType>({
    ...DEFAULT_INPUT_VALUE,
    value: defaultStake,
  });

  const maxLimit = useBetSlipMaxLimit({ isConnectedApp, betUid }) as number;

  const handleChangeStake = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setUiStake({ value, errorText: '' });

    setStake({ betUid, stake: value });
  };

  const resetValue = () => {
    setUiStake({ value: '', errorText: '' });
    setStake({ betUid, stake: '' });
  };

  const handleSetMaxBet = (maxBet: string) => {
    setUiStake({ value: maxBet, errorText: '' });

    setStake({ betUid, stake: maxBet });
  };

  useEffect(() => {
    if (!maxLimit) {
      return;
    }

    const { value, errorText } = uiStake;

    if (errorText || !value) {
      return;
    }

    if (+value <= maxLimit) {
      return;
    }

    setUiStake({ value, errorText: `T.More then max:${maxLimit}` });
    setStake({ betUid, stake: '' });
  }, [uiStake, maxLimit]);

  return (
    <div className={styles.actionBox}>
      <BaseInputUi
        inputStyleType="border-colorful"
        placeholder=""
        size="small"
        inputMode="numeric"
        errorText={uiStake.errorText ? ' ' : ''}
        disabled={isError}
        value={uiStake.value}
        isHidePlaceholder
        classes={styles.input}
        handleChange={handleChangeStake}
      >
        <div className={styles.trashBox} onClick={resetValue}>
          <TrashSvg />
        </div>
      </BaseInputUi>

      {!isError && (
        <MaxBet
          setUiStake={handleSetMaxBet}
          isExpress={betUid === ECustomBetUid.Express}
          betUid={betUid}
          isPrematch={isPrematch}
          eventUid={eventUid}
          lineUid={lineUid}
          outcomeId={outcomeId}
          errorText={uiStake.errorText}
        />
      )}
    </div>
  );
};

export default BetSlipAmount;
