import { useEffect, useState } from 'react';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';
import { ReactComponent as InfoSvg } from 'assets/base/info.svg';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { Text } from 'decryption_component-kit';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { Steps } from 'stores/money/payments/types';

import { INR_WITH_VPA } from 'constants/marketingTags';
import { isFilledForm } from 'utils/validation';

import styles from './InrDefaultDeposit.module.css';
import depositStyles from '../../../Deposit.module.css';

type Props = {
  limit: { min: number; max: number };
  requestId:
    | ERequestIds.GoogleInrDeposit
    | ERequestIds.DepositPhonePe
    | ERequestIds.DepositPayTm;
};

const InrDefaultDeposit = ({ limit, requestId }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const [vpa, setVpa] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const handleChangeVpa = (value: string) => {
    const errorText = !value.includes('@') ? 'errors.invalidDate' : '';

    setVpa({ errorText, value });
  };

  useEffect(() => {
    const data = INR_WITH_VPA ? [amount, vpa] : [amount];

    const isFilled = isFilledForm(data);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          channelId: PaymentChannelType.InrDeposit,
          transaction: vpa.value,
          currencyId,
          requestId,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
    // channelId,
  }, [currencyId, vpa, amount, requestId]);

  return (
    <>
      <AmountInput
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.amount"
        size="full"
        onChange={setAmount}
        min={limit.min}
        max={limit.max}
        classes={depositStyles.amountInputMargin}
        wrapperClasses={depositStyles.marginInputBottom}
      />

      {INR_WITH_VPA && (
        <Input
          placeholder="VPA/UPI ID"
          size="full"
          errorText={vpa.errorText}
          inputStyleType="border-colorful"
          inputLabelType="labelUp"
          onChange={handleChangeVpa}
          classes={depositStyles.marginInputBottom}
        />
      )}

      <div className={styles.row}>
        <InfoSvg className={styles.icon} />

        <div className={styles.text}>
          <Text idT="walletPopUp.forms.googleDepositDescription" />
        </div>
      </div>
    </>
  );
};

export default InrDefaultDeposit;
